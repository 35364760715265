import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { Navbar } from "../../components/Navbar/Navbar";
import SignUp from './SignUp';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Content from './Content';

export default function SignInSide() {
  return (
    <Box>
      <Navbar sideBarOpen={true} />
      <Stack
        direction="column"
        component="main"
        sx={[
          {
            justifyContent: 'center',
            height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
            marginTop: 'max(40px - var(--template-frame-height, 0px), 0px)',
            minHeight: '100%',
          },
          (theme) => ({
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              inset: 0,
              backgroundImage:
                'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
              backgroundRepeat: 'no-repeat',
            },
          }),
        ]}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            mx: 'auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            backgroundSize: 'large', 
            backdropFilter: "blur(75px)", // Optional blur effect
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              justifyContent: 'center',
              gap: { xs: 6, sm: 12 },
              p: { xs: 2, sm: 4 },
              m: 'auto',
            }}
          >
            <Content />
            <SignUp />
            </Stack>
        </Stack>
        <Divider sx={{mx:18, borderColor: alpha("#1c4ed8", 0.5)}}/>
      </Stack>
    </Box>
  );
}