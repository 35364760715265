import React from 'react';
import { Box } from '@mui/material';

export const PDFViewer = ({ pdfUrl }) => {
  return (
    <Box sx={{ 
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 1,
      display: 'flex',
      overflow: 'scroll',
      height: 600
    }}>
      <iframe
        src={pdfUrl}
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Invoice PDF"
      />
    </Box>
  );
};