import React from 'react';
import { useContext } from "react";
import { Box, Button, Typography, Chip } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../utils/AuthContext";
import { useTranslation } from 'react-i18next';

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

export const HeroSection = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSignIn = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/login'); 
    }
  };

  return (
    
    <Box
      component="section"
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        background: "radial-gradient(ellipse 90% 55% at 50% -20%, hsl(210, 100%, 90%), transparent)"
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 15 }}>
        <Chip
          label={t("New: AI-Powered Invoice Analysis ✨")}
          variant="outlined"
          sx={{ fontSize: '1rem', padding: '0.5rem 1.5rem', color: '#1d4ed8', borderColor: '#1d4ed8' }}
        />
      </Box>
      <Box sx={{ 
        mx: 'auto',
        height: '100%',
        mt: -2,
        px: { xs: 3, sm: 6, lg: 8 },
        textAlign: 'center'
      }}>
        <motion.div {...fadeIn}>
          
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.75rem' },
              fontWeight: 700,
              letterSpacing: '-0.025em',
              lineHeight: 1.2,
              mb: 3,
              color: '#1d4ed8',
            }}
          >
            {t("Transform Your")} <Box component="span" sx={{ color: 'black' }}>{t("Invoice Processing")}</Box>
            <br />
            <Box 
              component="span" 
              sx={{ 
                background: 'linear-gradient(to right, #1d4ed8, #60a5fa)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block'
              }}
            >
              {t("with AI Intelligence")}
            </Box>
          </Typography>

          <Typography
            sx={{
              fontSize: '1.25rem',
              color: 'text.secondary',
              mx: 'auto',
              mb: 4,
            }}
          >
            {t("Automate your workflow and gain actionable insights with our enterprise-grade platform.")}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleSignIn}
              sx={{ 
                textTransform: 'none',
                px: 4, 
                py: 1, 
                borderRadius: '9999px',
                bgcolor: '#1d4ed8',
                fontSize: '1rem',
              }}
            >
              {t("Start Now")}
            </Button>
            <Button
              variant="outlined"
              onClick={handleSignIn}
              sx={{ 
                textTransform: 'none',
                px: 4, 
                py: 1, 
                color: '#1d4ed8',
                borderRadius: '9999px',
                bgcolor: 'rgba(255,255,255,0.1)',
                backdropFilter: 'blur(4px)',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #1d4ed8',
                gap: 1,
                fontSize: '1rem',
                '&:hover': {
                        backgroundColor: 'white',
                        borderColor: '#1d4ed8'
                      }
              }}
            >
              {t("See How It Works")} <ArrowRight />
            </Button>
          </Box>
        </motion.div>

        <Box sx={{ position: 'relative', mt: 8, width: '100%', height: '100%', pb: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src="/page.avif"
              alt="Dashboard Analytics"
              sx={{
                width: { xs: '90%', sm: '80%', md: '80%' },
                height: 'auto',
                borderRadius: '2.5rem',
                boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)'
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
