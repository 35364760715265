import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import useAxios from "../../utils/useAxios";

export const EmailInvoiceModal = ({ open, onClose, invoiceIds, url }) => {
  const demo = !localStorage.getItem("authTokens");
  const { t } = useTranslation();
  const axios = useAxios();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      const requestBody = {
        email: email,
        invoice_ids: invoiceIds
      };

      let response;
      if (demo) {
        response = await fetch(`${url}/api/invoices/email/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (response.ok) {
          setSuccess(true);
          setTimeout(() => {
            onClose();
          }, 2000);
        } else {
          setError(data.error || t('Failed to send email. Please try again.'));
        }
      } else {
        response = await axios.post(`${url}/api/invoices/email/`, requestBody);
        setSuccess(true);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } catch (error) {
      setError(error.response?.data?.error || t('An error occurred while sending the email.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={loading ? undefined : onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '24px',
          padding: 2,
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        pb: 1
      }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          {t('Send Invoice by Email')}
        </Typography>
        {!loading && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 2 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {t('Email sent successfully!')}
            </Alert>
          )}
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('Recipient Email Address')}
            </Typography>
            <TextField
              autoFocus
              required
              fullWidth
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('Enter email address')}
              disabled={loading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                }
              }}
            />
          </Box>
          
          <Typography variant="body2" color="text.secondary">
            {t('Selected Invoices')}: {invoiceIds.length}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            variant="outlined"
            sx={{
              borderRadius: '50px',
              borderColor: '#1c4ed8',
              color: '#1c4ed8',
              '&:hover': {
                borderColor: '#1c4ed8',
                backgroundColor: 'rgba(28, 78, 216, 0.04)',
              },
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading || !email}
            startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
            sx={{
              borderRadius: '50px',
              backgroundColor: '#1c4ed8',
              '&:hover': {
                backgroundColor: '#1e40af',
              },
            }}
          >
            {loading ? t('Sending...') : t('Send')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}; 