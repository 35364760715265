// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set base font family for the entire application */
* {
  font-family: 'Funnel Display', serif;
}

/* Light weight (300) for regular text */
p, 
span,
div,
.body-text,
.info-block {
  font-weight: 400;
}

/* Medium weight (500) for interactive and heading elements */
button,
.button,
h1, h2, h3, h4, h5, h6,
.list-heading,
.headline {
  font-weight: 500;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,oCAAoC;AACtC;;AAEA,wCAAwC;AACxC;;;;;EAKE,gBAAgB;AAClB;;AAEA,6DAA6D;AAC7D;;;;;EAKE,gBAAgB;AAClB","sourcesContent":["/* Set base font family for the entire application */\n* {\n  font-family: 'Funnel Display', serif;\n}\n\n/* Light weight (300) for regular text */\np, \nspan,\ndiv,\n.body-text,\n.info-block {\n  font-weight: 400;\n}\n\n/* Medium weight (500) for interactive and heading elements */\nbutton,\n.button,\nh1, h2, h3, h4, h5, h6,\n.list-heading,\n.headline {\n  font-weight: 500;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
