import React from 'react';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const InvoicePreview = ({ invoice, extractedText }) => {
  // Escape pipes that aren't part of table syntax
  const escapeNonTablePipes = (text) => {
    // Split into lines
    return text.split('\n').map(line => {
      // Only escape pipes if the line doesn't look like a table row
      // (table rows typically have pipes at start/end and multiple pipes)
      if (!line.trim().startsWith('|') && !line.trim().endsWith('|')) {
        return line.replace(/\|/g, '\\|');
      }
      return line;
    }).join('\n');
  };

  return (
    <Box sx={{ 
      bgcolor: 'background.paper', 
      p: 2, 
      borderRadius: 2,
      boxShadow: 1
    }}>
      <Box sx={{ 
        mb: 3,
        maxHeight: '550px',
        overflow: 'scroll',
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
          margin: '16px 0'
        },
        '& td, & th': {
          border: '1px solid #ddd',
          padding: '8px',
          textAlign: 'left'
        },
        '& th': {
          backgroundColor: '#f5f5f5'
        },
        '& p': {
          margin: '8px 0'
        },
        '& ul': {
          paddingLeft: '20px',
          margin: '8px 0'
        }
      }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({children}) => <p style={{fontSize: '0.875rem'}}>{children}</p>,
            li: ({children}) => <li style={{fontSize: '0.875rem'}}>{children}</li>,
            td: ({children}) => <td style={{fontSize: '0.875rem'}}>{children}</td>,
            th: ({children}) => <th style={{fontSize: '0.875rem'}}>{children}</th>
          }}
        >
          {escapeNonTablePipes(extractedText)}
        </ReactMarkdown>
      </Box>
    </Box>
  );
};