//useAxios.js
import { useMemo } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";


const useAxios = () => {
    const internalUrl = process.env.REACT_APP_API_BASE_URL;
    const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}/api` : 'http://127.0.0.1:8000/api';
    }, [internalUrl]);

  const { authTokens, setUser, setAuthTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    url,
    headers: authTokens ? { Authorization: `Bearer ${authTokens.access}` } : {}
  });

  axiosInstance.interceptors.request.use(async req => {
    if (!authTokens) return req;

    const user = jwtDecode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    try {
      const response = await axios.post(`${url}/token/refresh/`, {
        refresh: authTokens.refresh
      });

      localStorage.setItem("authTokens", JSON.stringify(response.data));
      setAuthTokens(response.data);
      setUser(jwtDecode(response.data.access));
      req.headers.Authorization = `Bearer ${response.data.access}`;
      return req;
    } catch (error) {
      // Handle refresh token failure
      await logoutUser();
      navigate('/login');
      throw error; // This will cause the original request to fail
    }
  });

  return axiosInstance;
};

export default useAxios;