import * as React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';

function ForgotPassword({ open, handleClose }) {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleContinue = async () => {
    const email = document.getElementById('email').value;
    
    // Add email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(t('Please enter a valid email address'));
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${url}/api/forgot-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message || t('Password reset link has been sent to your email'));
        setError('');
      } else {
        const data = await response.json();
        setError(data.error || t('Something went wrong'));
      }
    } catch (err) {
      setError(t('Network error')); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
        },
        sx: { backgroundImage: 'none', justifyContent: 'center', alignItems: 'center' },
      }}
    >
      <DialogTitle>{successMessage ? t("Success") : t("Reset password")}</DialogTitle>
      <DialogContent
        sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', 
          gap: 2, 
          width: '500px',  // Set fixed width for both states
          minWidth: '90%'  // Maintain minimum width for responsiveness
        }}
      >
        {!successMessage ? (
          <>
            <DialogContentText>
              {t("Enter your account's email address, and we'll send you a link to reset your password.")}
            </DialogContentText>
            {error && (
              <DialogContentText color="error">
                {error}
              </DialogContentText>
            )}
            <FormControl fullWidth>
              <InputLabel htmlFor="email">{t("Email address")}</InputLabel>
              <OutlinedInput
                autoFocus
                required
                margin="dense"
                id="email"
                name="email"
                label={t("Email address")}
                type="email"
                fullWidth
              />
            </FormControl>
          </>
        ) : (
          <DialogContentText>
            {successMessage}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3, width: '80%' }}>
        {!successMessage ? (
          <>
            <Button onClick={handleClose} sx={{color: '#1c4ed8'}}>
              {t('Cancel')}
            </Button>
            <Button 
              onClick={handleContinue} 
              variant="contained" 
              disabled={loading}
              sx={{borderRadius: '50px', backgroundColor: '#1c4ed8'}}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : t("Continue")}
            </Button>
          </>
        ) : (
          <Button 
            onClick={handleClose}
            variant="contained" 
            sx={{borderRadius: '50px', backgroundColor: '#1c4ed8'}}
          >
            {t("Close")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;