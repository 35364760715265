import React from 'react';
import { motion } from 'framer-motion';
import { 
  Box, 
  Typography, 
  Grid, 
  IconButton
} from '@mui/material';
import { LayoutDashboard, Zap, BarChart } from 'lucide-react';
import { useTranslation } from 'react-i18next';

// Animation variants
const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut'
    }
  }
};

const contentVariants = {
  initial: { opacity: 0, x: -50 },
  animate: { 
    opacity: 1, 
    x: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut'
    }
  }
};

const imageVariants = {
  initial: { scale: 1 },
  hover: { 
    scale: 1.03,
    transition: { duration: 0.3, ease: 'easeOut' }
  }
};

const iconVariants = {
  initial: { rotate: 0 },
  hover: { 
    rotate: [0, -10, 10, 0],
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    }
  }
};

export const FeatureCard = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: BarChart,
      title: t('Advanced Analytics'),
      description: t('Gain insights into your business with a powerful dashboard for invoice trends, metrics, and performance analytics.'),
      demo: t('Explore Dashboard'),
      path: '/analytics',
      image: 'page.avif',
      imagePosition: 'left'
    },
    {
      icon: LayoutDashboard,
      title: t('Intelligent Dashboard'),
      description: t('Comprehensive overview of your invoices with real-time insights and analytics.'),
      demo: t('See live demo'),
      path: '/dashboard',
      image: 'dashboard.avif',
      imagePosition: 'right'
    },
    {
      icon: Zap,
      title: t('AI-Powered Automation'),
      description: t('Our advanced algorithms automatically extract and validate invoice data with high accuracy.'),
      demo: t('View AI in action'),
      path: '/dashboard',
      image: 'invoice_detail.avif',
      imagePosition: 'left'
    },
    // {
    //   icon: Bot,
    //   title: t('Personal Assistant'),
    //   description: t('Interact with an intelligent virtual assistant for instant answers and support on invoice-related queries, legal and accounting.'),
    //   demo: t('Chatbot Demo'),
    //   path: '/chatbot',
    //   image: 'chatbot.png',
    //   imagePosition: 'right'
    // }
  ];

  return (
    <Box 
      component={motion.div}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      sx={{ 
        position: 'relative',
        py: 12,
        px: { xs: 2, sm: 4, md: 8 },
      }}
    >
      {features.map((feature, index) => (
        <Grid 
          container 
          spacing={12} 
          alignItems="center"
          key={index}
          sx={{ mb: index !== features.length - 1 ? 12 : 0 }}
        >
          {/* Image Section */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{ order: { md: feature.imagePosition === 'left' ? 1 : 2 } }}
          >
            <motion.div
              whileHover="hover"
              initial="initial"
              variants={imageVariants}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 400,
                  borderRadius: '24px',
                  overflow: "hidden",
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'white',
                  transition: 'box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',
                  }
                }}
              >
                <Box
                  component="img"
                  src={`${feature.image}`}
                  alt={feature.title}
                  loading="lazy"
                  decoding="async"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                <link rel="preload" as="image" href={`${feature.image}`}/>
              </Box>
            </motion.div>
          </Grid>

          {/* Content Section */}
          <Grid 
            item 
            xs={12} 
            md={6}
            sx={{ order: { md: feature.imagePosition === 'left' ? 2 : 1 } }}
          >
            <Box
              component={motion.div}
              variants={contentVariants}
            >
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 700,
                  mb: 3,
                  color: 'text.primary',
                  lineHeight: 1.2
                }}
              >
                <motion.div
                  whileHover="hover"
                  initial="initial"
                  variants={iconVariants}
                  style={{ display: 'inline-block' }}
                >
                  <IconButton>
                    <feature.icon size={36}  color='#1c4ed8' />
                  </IconButton>
                </motion.div>
                {feature.title}
              </Typography>

              <Typography 
                variant="body1"
                color="text.secondary"
                sx={{ 
                  mb: 4,
                  fontSize: '1.125rem',
                  lineHeight: 1.7,
                  maxWidth: '90%'
                }}
              >
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};


