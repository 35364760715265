import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

const InvoiceCustomerAnalysis = ({ data }) => {
  const theme = useTheme();

  // Transform the data from [["CUSTOMER_NAME", count], ...] format
  const chartData = data.map(([customer, count]) => ({
    customer,
    invoiceCount: count
  }));

  const totalInvoices = chartData.reduce((sum, customer) => sum + customer.invoiceCount, 0);

  return (
    <Card variant="outlined" sx={{ width: '615px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold" gutterBottom>
          Top Customer Analysis
        </Typography>
        <Stack sx={{ justifyContent: 'space-between', mb: 2 }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {totalInvoices} Invoices
            </Typography>
            <Chip size="small" color="success" label="+X% (Last Month)" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Summary of invoice count by customer
          </Typography>
        </Stack>
        <Box sx={{ width: '100%', height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="customer" type="category" width={120} />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="invoiceCount"
                fill={(theme.vars || theme).palette.primary.main}
                name="Invoice Count"
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InvoiceCustomerAnalysis;

