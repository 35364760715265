import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, Receipt } from 'lucide-react';
import { Box, Typography, Button } from '@mui/material';

export const DropZone = ({ onFilesDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg']
    },
    onDrop: onFilesDrop
  });

  const handleTrySample = async () => {
    try {
      const response = await fetch('/receipt.jpg');
      const blob = await response.blob();
      const file = new File([blob], 'sample-receipt.jpg', { type: 'image/jpeg' });
      onFilesDrop([file]);
    } catch (error) {
      console.error('Error loading sample receipt:', error);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 3,
        textAlign: 'center',
        boxShadow: 1,
        mb: 20,
      }}
    >
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed',
          borderColor: 'primary.main',
          borderRadius: 2,
          p: 4,
          mb: 3,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}>
          <Upload size={48} style={{ marginBottom: '16px' }} />
          <Typography variant="h6" fontWeight="medium">
            {isDragActive ? 'Drop the receipts here' : 'Drag & drop receipts here'}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            or click to select files
          </Typography>
        </Box>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        - or -
      </Typography>

      <Button
        onClick={handleTrySample}
        startIcon={<Receipt size={20} />}
        variant="outlined"
        sx={{
          borderRadius: '10px',
          textTransform: 'none',
          px: 3,
          py: 1,
          color: '#1c4ed8',
          borderColor: '#1c4ed8',
        }}
      >
        Try with sample receipt
      </Button>
    </Box>
  );
};