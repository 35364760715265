import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  IconButton,
  Container,
  Chip,
  Tooltip,
  TextField,
  InputAdornment,
  ClickAwayListener,
  Menu,
  MenuItem,
  Stack,
  CircularProgress,
  Typography,
  Dialog, 
  DialogContent, 
  DialogActions,
  Select, 
  FormControl, 
  InputLabel,
  useTheme,
  Divider,
  List,
  ListItemText,
  Checkbox,
  ListItemIcon
} from "@mui/material";
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { DataGrid} from '@mui/x-data-grid';
import { plPL } from '@mui/x-data-grid/locales/';  // Polish locale
import {
  Download as DownloadIcon,
  Upload as UploadIcon,
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
  Pending as PendingIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Article,
  Sync,
  Done,
  LocationOn as LocationOnIcon, 
  CheckCircleOutline as CheckCircleOutlineIcon,
  Email as EmailIcon,
  Sync as SyncIcon,
} from "@mui/icons-material";
import { FilterList as FilterIcon } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";
import { MapPinPlus, FileText } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import useAxios from "../../utils/useAxios";
import { useBusinessContext}  from "../../utils/BusinessContext";
import { AddBusinessModal} from "../../utils/BusinessContext";
import { motion } from "framer-motion";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { Navbar } from "../../components/Navbar/Navbar";
import { AddFrame } from "../../components/AddFrame/AddFrame";
import { useNavigate } from 'react-router-dom';
import SideBar from "../../components/SideBar/SideBar";
import { EmailInvoiceModal } from "../../components/EmailInvoiceModal/EmailInvoiceModal";
import { ExportFrame } from "../../components/ExportFrame/ExportFrame";
import XeroLogo from '../Membership/xero-logo.png';
import QuickBooksLogo from '../Membership/quickbooks-logo.png';
import SageLogo from '../Membership/sage-logo.png';

// Helper functions for status-related operations
const getStatusIcon = (status) => {
  switch (status) {
    case "PENDING_REVIEW":
      return <PendingIcon />;
    case "IN_REVIEW":
      return <EditIcon />;
    case "APPROVED":
      return <CheckCircleIcon />;
    default:
      return <PendingIcon />;
  }
};

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};


const FilterMenu = ({ onFilterChange, t, i18n }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const categories = [
    "Advertising & Marketing",
    "Agriculture & Food Production",
    "Arts & Culture",
    "Automotive",
    "Craftsmanship", 
    "Design",
    "Education",
    "Energy & Utilities",
    "Entertainment & Media",
    "Finance",
    "Food & Beverage",
    "Healthcare",
    "Home & Lifestyle",
    "Logistics & Transportation",
    "Luxury Retail",
    "Manufacturing & Industry",
    "Non-Profit & Social Impact",
    "Personal Services",
    "Real Estate",
    "Retail",
    "Science & Development",
    "Security",
    "Sport & Recreation", 
    "Sustainability & Environment",
    "Technology",
    "Telecommunications",
    "Tourism",
    "Uncategorized"
  ];

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryToggle = (category) => {
    const newSelection = selectedCategories.includes(category)
      ? selectedCategories.filter(item => item !== category)
      : [...selectedCategories, category];
    
    setSelectedCategories(newSelection);
    onFilterChange?.({ type: "categories", value: newSelection });
  };

  const clearSelection = () => {
    setSelectedCategories([]);
    onFilterChange?.({ type: "categories", value: [] });
  };

  return (
    <Box>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleFilterClick}
          size="small"
          sx={{
            ml: 2,
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: '#1c4ed8',
            color: '#1c4ed8',
            '&:hover': {
              backgroundColor: 'background.paper',
              opacity: 0.9
            }
          }}
        >
          <FilterIcon  />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            width: i18n.language === 'pl' ? 293 : 255,
            maxHeight: 340,
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 320,
          }}
        >
          {/* Title Section */}
          <Box
            sx={{
              px: 2,
              py: 1,
              flexShrink: 0, // Prevent shrinking
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
              }}
            >
              Filter by Item Category
            </Typography>
          </Box>

          <Divider />

          {/* Scrollable List */}
          <List
            sx={{
              flexGrow: 1, // Allow the list to grow and take up available space
              overflowY: 'auto', // Enable vertical scrolling
              py: 0,
            }}
          >
            {categories.map((category) => (
              <MenuItem
                key={category}
                onClick={() => handleCategoryToggle(category)}
                sx={{
                  px: 2,
                  py: 0.5,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <Checkbox
                    edge="start"
                    checked={selectedCategories.includes(category)}
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: '#1c4ed8', // Color when checked
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t(category)}
                  primaryTypographyProps={{
                    variant: 'body2',
                  }}
                />
              </MenuItem>
            ))}
          </List>

          <Divider />

          {/* Clear Selection Section */}
          <Box
            sx={{
              px: 2,
              py: 1,
              flexShrink: 0, // Prevent shrinking
            }}
          >
            <Typography
              variant="body2"
              onClick={clearSelection}
              sx={{
                color: '#1c4ed8',
                cursor: 'pointer',
              }}
            >
              Clear Selection
            </Typography>
          </Box>
        </Box>
      </Menu>

    </Box>
  );
};

export const Dashboard = () => {
  const demo = !localStorage.getItem("authTokens");
  const { currentBusiness, businesses, switchBusiness, addBusiness } = useBusinessContext();
  const [isAddBusinessModalOpen, setIsAddBusinessModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem('cachedBusinessLocation') || '');
  const [availableLocations, setAvailableLocations] = useState(() => {
    try {
      const stored = localStorage.getItem('cachedBusinessLocations');
      return stored ? JSON.parse(stored) : [];
    } catch (e) {
      console.warn('Error parsing cached business locations:', e);
      return [];
    }
  });
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const axios = useAxios();
  const theme = useTheme();
  const proSubscription = JSON.parse(localStorage.getItem('proSubscription'));
  
  // State management
  const [invoices, setInvoices] = useState([]);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [showAddFrame, setShowAddFrame] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [, setAllChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("All Invoices");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedInvoiceIds, setCheckedInvoiceIds] = useState([]);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadAnchorEl, setUploadAnchorEl] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showExportFrame, setShowExportFrame] = useState(false);
  const [xeroIntegrationStatus, setXeroIntegrationStatus] = useState('disconnected');
  const [quickbooksIntegrationStatus, setQuickbooksIntegrationStatus] = useState('disconnected');
  const [sageIntegrationStatus, setSageIntegrationStatus] = useState('disconnected');
  const [, setStatus] = useState(null);

  // Memoized URL
  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const searchRef = useRef(null);
  const open = Boolean(anchorEl)
  
  // Update cache whenever currentBusiness changes
  useEffect(() => {
    const updateBusinessState = async () => {
      if (!demo && currentBusiness && currentBusiness.company_name) {
        localStorage.setItem('cachedBusinessName', currentBusiness.company_name);
        if (currentBusiness.company_address) {
          setSelectedLocation(currentBusiness?.company_address);
        }
        setAvailableLocations(
          (businesses || []).map((company) => ({
            company: company.company_name,
            address: company.company_address,
          }))
        );
        // Only fetch invoices if business changes
        await fetchInvoices();
      } else if (demo) {
        setSelectedLocation('');
        setAvailableLocations([]);
        await fetchInvoices();
      }
    };

    updateBusinessState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness?.company_name, demo]); 


  // Update the useEffect for fetching integration statuses
  useEffect(() => {
    const fetchIntegrationStatuses = async () => {
      try {
        const response = await axios.get(`${url}/api/subscription/membership/`);
        if (response.data?.subscription) {
          const now = new Date();

          // Helper function to check integration status
          const checkIntegrationStatus = (integration, expiryDays) => {
            if (integration?.refresh_token && integration?.expires_at) {
              const expiresAt = new Date(integration.expires_at);
              // Add expiry days worth of milliseconds
              expiresAt.setDate(expiresAt.getDate() + expiryDays);
              return expiresAt > now ? 'connected' : 'disconnected';
            }
            return 'disconnected';
          };

          // Check status for each integration
          const subscription = response.data.subscription;
          setXeroIntegrationStatus(
            checkIntegrationStatus(subscription.xero_integration, 60)
          );
          setQuickbooksIntegrationStatus(
            checkIntegrationStatus(subscription.quickbooks_integration, 100)
          );
          setSageIntegrationStatus(
            checkIntegrationStatus(subscription.sage_integration, 31)
          );
        }
      } catch (error) {
        console.error('Failed to fetch integration statuses:', error);
        // Set default disconnected states if fetch fails
        setXeroIntegrationStatus('disconnected');
        setQuickbooksIntegrationStatus('disconnected');
        setSageIntegrationStatus('disconnected');
      }
    };

    if (!demo) {
      fetchIntegrationStatuses();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  // Memoized TabButton component
  const TabButton = React.memo(({ tab, activeTab, onClick }) => (
    <Button
      variant={activeTab === tab ? "contained" : "text"}
      onClick={() => onClick(tab)}
      startIcon={
        tab === "All Invoices" ? <Article /> : 
        tab === "Approved" ? <Done /> : 
        <Sync />
      }
      sx={{
        size: "small",
        borderRadius: '50px',
        backgroundColor: activeTab === tab ? 'white' : 'transparent',
        color: '#1c4ed8',
        '&:hover': {
          color: '#1c4ed8',
          backgroundColor: 'white',
        },
      }}
    >
      {t(tab)}
    </Button>
  ));

  const renderStatusChip = (status) => {
    const statusConfig = {
      'PENDING_REVIEW': { label: t('Pending Review'), color: 'warning' },
      'IN_REVIEW': { label: t('In Review'), color: 'info' },
      'APPROVED': { label: t('Approved'), color: 'success' }
    };
  
    const config = statusConfig[status] || { label: 'Unknown', color: 'default' };
    return (
      <Chip
        label={config.label.toUpperCase()}
        color={config.color}
        icon={getStatusIcon(status)}
        variant="outlined"
        size="small"
      />
    );
  }

    // Columns Definition
    const columns = useMemo(() => [
      { 
        field: 'thumbnail', 
        headerName: t('File'), 
        flex: 0.18, 
        renderCell: (params) => {
          return (
            <Box sx={{ 
              width: '100%', 
              height: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center'
            }}>
              <FileText style={{ color: '#3b82f6', fontSize: '40px' }} />
            </Box>
          );
        },
      },
      { 
        field: 'fileName', 
        headerName: t('File Name'), 
        flex: 0.7, 
        description: t('The name of the file.'),
      },
      { 
        field: 'status', 
        headerName: t('Status'), 
        flex: 0.5, 
        renderCell: (params) => renderStatusChip(params.value),
        description: t('The current processing status of the file.')
      },
      { 
        field: 'submittedAt', 
        headerName: t('Update Date'), 
        flex: 0.5,
        description: t('The upload date of the file.'),
      },
      { 
        field: 'modifiedAt', 
        headerName: t('Last Modified'), 
        flex: 0.5,
        description: t('The last update date of the file.'),
      },
      { 
        field: 'uploaded_by', 
        headerName: t('Updated By'), 
        flex: 0.5,
  
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [t, url]);

  // Memoized filtered invoices
  const filteredInvoices = useMemo(() => {
    if (!searchQuery) return invoices;
    return invoices.filter(invoice => 
      invoice.file.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [invoices, searchQuery]);

  // Debounce utility function
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Memoized handlers
  const handleDownloadClick = useCallback(() => {
    setShowDownloadFrame(true);
    setAnchorEl(null); // Close the dropdown menu
  }, []);
  const handleAddClick = useCallback(() => setShowAddFrame(true), []);

  // Optimize fetchInvoices with useCallback and proper dependencies
  const fetchInvoices = useCallback(async () => {
    if (isLoading) return; // Prevent concurrent calls
    
    setIsLoading(true);
    try {
      if (demo) {
        const response = await fetch(`${url}/api/invoices/`);
        const data = await response.json();
        if (data.message === "Success") {
          const processedInvoices = data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file_name,
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
          }));
          setInvoices(processedInvoices);
        }
      } else {
        const response = await axios.get(`${url}/api/invoices/`);
        if (response.data.message === "Success") {
          const processedInvoices = response.data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file_name,
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
          }));
          setInvoices(processedInvoices);
        }
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url, isLoading]);

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const handleRowClick = (params) => {
    const invoice = {
      id: params.row.id,
      file: params.row.file,
      status: params.row.status,
      addedBy: params.row.uploaded_by,
      addedOn: params.row.uploaded_at,
      fileName: params.row.fileName
    }
    handleGoInvoice([invoice]);
  };

  const updateStatus = useCallback(async (invoiceId) => {
    if (demo) {
      try {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status: "IN_REVIEW" })
        });
        
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        
        // Fetch invoices after status update
        await fetchInvoices();
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    } else {
      try {
        await axios.post(`${url}/api/invoices/${invoiceId}/`, 
          { status: "IN_REVIEW" },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        // Fetch invoices after status update
        await fetchInvoices();
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url, fetchInvoices]);

  // Modify handleGoInvoice to properly handle the status update
  const handleGoInvoice = useCallback(async(invoices) => {
    try {
      const invoiceIds = invoices.map((invoice) => invoice.id).join(',');
      const filePaths = invoices.map((invoice) => invoice.file).join(',');
      const addedByList = invoices.map((invoice) => invoice.addedBy || invoice.uploaded_by);
      const addedOnList = invoices.map((invoice) => invoice.addedOn || invoice.uploaded_at);
      const fileNameList = invoices.map((invoice) => invoice.fileName || invoice.file_name);
      const invoiceId = invoiceIds.split(",")[0];

      // If any invoice has "PENDING_REVIEW" status, update it
      updateStatus(invoices[0].id);

      
      // Navigate after ensuring status is updated
      navigate(`/invoice/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}&addedByList=${encodeURIComponent(addedByList)}&addedOnList=${encodeURIComponent(addedOnList)}&fileNameList=${encodeURIComponent(fileNameList)}`);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  }, [navigate, updateStatus]);

  // Modify handleCloseUploadFrame to ensure state is updated
  const handleCloseUploadFrame = useCallback(async (invoices) => {
    setShowUploadFrame(false);    
    if (invoices.length === 0) {
      await fetchInvoices();
      return;
    }

    try {
      // Update status and navigate
      await handleGoInvoice(invoices);
      // Make sure to fetch updated invoices when returning to dashboard
      await fetchInvoices();
    } catch (error) {
      console.error('Error handling uploaded invoices:', error);
      fetchInvoices();
    }
    
  }, [fetchInvoices, handleGoInvoice]);

  // Update handleCloseAddFrame similarly
  const handleCloseAddFrame = useCallback(async (invoices) => {
    setShowAddFrame(false);
    if (invoices.length === 0) {
      await fetchInvoices();
      return;
    }
    try {
      await handleGoInvoice(invoices);
      // Make sure to fetch updated invoices when returning to dashboard
      fetchInvoices();
    } catch (error) {
      console.error('Error handling added invoices:', error);
      fetchInvoices();
    }

  }, [fetchInvoices, handleGoInvoice]);

  const handleSetActiveTab = useCallback((newTab) => {
    setActiveTab(newTab);
    
    let status = null;
    if (newTab === 'In Review') {
      status = 'IN_REVIEW';
    } else if (newTab === 'Approved') {
      status = 'APPROVED';
    }
    
    const apiUrl = `${url}/api/invoices/${status ? `?status=${status}` : ''}`;
    if (demo) {
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          if (data.message === "Success") {
            const processedInvoices = data.data.map(invoice => ({
              ...invoice,
              fileName: invoice.file_name,
              submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
              modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
            }));
            setInvoices(processedInvoices);
          }
        })
        .catch(error => {
          console.error("Error fetching invoices:", error);
        });
    } else {
      axios.get(apiUrl)
      .then(response => {
        if (response.data.message === "Success") {
          const processedInvoices = response.data.data.map(invoice => ({
            ...invoice,
            fileName: invoice.file_name,
            submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
            modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
          }));
          setInvoices(processedInvoices);
        }
      })
      .catch(error => {
        console.error("Error fetching invoices:", error);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);
      await Promise.all(
        checkedInvoiceIds.map((invoiceId) =>
          demo
            ? fetch(`${url}/api/invoices/${invoiceId}/`, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            : axios.delete(`${url}/api/invoices/${invoiceId}/`, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
        )
      );      
      await fetchInvoices();
      setCheckedInvoiceIds([]);
      setDeleteLoading(false);
      setAnchorEl(null);
    } catch (error) {
      console.error('Delete error:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInvoiceIds, demo, url]);

  const handleSearchChange = useMemo(() => {
    return debounce((value) => {
      const apiUrl = `${url}/api/invoices/${value ? `?search=${value}` : ''}`;
      if (demo) {
        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            if (data.message === "Success") {
              const processedInvoices = data.data.map(invoice => ({
                ...invoice,
                fileName: invoice.file_name,
                submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
                modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
              }));
              setInvoices(processedInvoices);
            }
          })
          .catch(error => {
            console.error("Error fetching invoices:", error);
          });
      } else {
        axios.get(apiUrl)
        .then(response => {
          if (response.data.message === "Success") {
            const processedInvoices = response.data.data.map(invoice => ({
              ...invoice,
              fileName: invoice.file_name,
              submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
              modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
            }));
            setInvoices(processedInvoices);
          }
        })
        .catch(error => {
          console.error("Error fetching invoices:", error);
        });
      }
    }, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);


  const handleCategory = useCallback((categories) => {
    const query = categories && categories.length > 0 
      ? `?${categories.map(cat => `category=${encodeURIComponent(cat)}`).join('&')}` 
      : '';
    const apiUrl = `${url}/api/invoices/${query}`;
  
    const processInvoices = (invoices) => {
      return invoices.map(invoice => ({
        ...invoice,
        fileName: invoice.file_name,
        submittedAt: new Date(invoice.uploaded_at).toLocaleDateString(),
        modifiedAt: new Date(invoice.updated_at).toLocaleDateString(),
      }));
    };
  
    const fetchData = async () => {
      try {
        if (demo) {
          const response = await fetch(apiUrl);
          const data = await response.json();
          if (data.message === "Success") {
            setInvoices(processInvoices(data.data));
          }
        } else {
          const response = await axios.get(apiUrl);
          if (response.data.message === "Success") {
            setInvoices(processInvoices(response.data.data));
          }
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };
  
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);
  

  // Effects
  useEffect(() => {
    if (filteredInvoices.length === 0) {
      setAllChecked(false);
    } else {
      setAllChecked(checkedInvoiceIds.length === filteredInvoices.length);
    }
  }, [checkedInvoiceIds, filteredInvoices]);

  useEffect(() => {
    fetchInvoices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showSearch && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  // Update handleConfirm to avoid duplicate calls
  const handleConfirm = async () => {
    if (!selectedLocation || selectedLocation === currentBusiness?.company_address) {
      setIsLocationModalOpen(false);
      return;
    }

    setIsLoading(true);
    try {
      const selected = availableLocations.find(location => location.address === selectedLocation);
      await switchBusiness(selected.company);
      // Remove separate calls to fetchBusinesses and fetchInvoices since they will be triggered by business change
      setIsLocationModalOpen(false);
    } catch (error) {
      console.error('Error switching business:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationModal = () => {
    setIsLocationModalOpen(true);
  }

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  }

  const handleCloseLocationModal = () => {
    if (!selectedLocation) {
      setSelectedLocation('');
    }
    setIsLocationModalOpen(false);
  };


  const handleAddBusiness = async (data) => {
    await addBusiness(data);
  }

  const handleFilterChange = (filterData) => {
    console.log('Selected category:', filterData.value);
    handleCategory(filterData.value);
  };

  const handleUploadMenuClick = (type) => {
    setUploadAnchorEl(null);
    if (type === 'receipt') {
      navigate('/receipt-converter');
    } else {
      setShowUploadFrame(true);
    }
  };

  const handleEmailClick = () => {
    setAnchorEl(null);
    setShowEmailModal(true);
  };

  return (
    <Box width="100%" bgcolor="white" 
    sx={{
      backgroundImage:
        'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      '&.dark': {
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
      },
    }}>
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
      <Navbar sideBarOpen={openSideBar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <Box sx={{ width: openSideBar ? '100%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
            <Container sx={{ mt: 14}}>
              <Box display='flex' justifyContent="right" alignItems="flex-end" flexDirection="column" >
                <Typography variant="h5" >
                  {localStorage.getItem('cachedBusinessName') || 'Demo Company'}
                  <MapPinPlus onClick={handleLocationModal} color="#1c4ed8" size="24px" 
                    style={{marginLeft: 2, marginRight: 4, cursor: 'pointer'}}/>
                </Typography>
                
                <AddBusinessModal 
                  isOpen={isAddBusinessModalOpen}
                  onClose={() => setIsAddBusinessModalOpen(false)}
                  onAddBusiness={handleAddBusiness}
                  proSubscription={proSubscription}
                />
              </Box>
            </Container>
            <Container>
              <Box mt={3} >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}
                  width={ "100%"}>
                  <Box display="flex" justifyContent="left" alignItems="center">
                    <Button
                      variant="contained"
                      size="small"
                      className="button"
                      startIcon={<UploadIcon />}
                      onClick={(event) => setUploadAnchorEl(event.currentTarget)}
                      sx={{ mr: 2, borderRadius: '50px' }}
                    >
                      {t("Upload")}
                    </Button>

                    <Menu
                      anchorEl={uploadAnchorEl}
                      open={Boolean(uploadAnchorEl)}
                      onClose={() => setUploadAnchorEl(null)}
                      PaperProps={{
                        elevation: 3,
                        sx: {
                          mt: 1,
                          borderRadius: 2,
                          minWidth: 200,
                        }
                      }}
                    >
                      <MenuItem 
                        onClick={() => handleUploadMenuClick('invoice')}
                        sx={{
                          py: 1.5,
                          '&:hover': {
                            backgroundColor: '#f0f4ff',
                          }
                        }}
                      >
                        <ListItemIcon>
                          <FileText style={{ color: '#1c4ed8' }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={t("Invoice")}
                          secondary={t("Upload invoice documents")}
                        />
                      </MenuItem>
                      <MenuItem 
                        onClick={() => handleUploadMenuClick('receipt')}
                        sx={{
                          py: 1.5,
                          '&:hover': {
                            backgroundColor: '#f0f4ff',
                          }
                        }}
                      >
                        <ListItemIcon>
                          <ReceiptIcon sx={{ color: '#1c4ed8' }} />
                        </ListItemIcon>
                        <ListItemText 
                          primary={t("Receipt")}
                          secondary={t("Upload receipt documents")}
                        />
                      </MenuItem>
                    </Menu>

                    <Box sx={{backgroundColor: '#f7f7f7', borderRadius: '50px'}}>
                      {["All Invoices", "In Review", "Approved"].map((tab) => (
                        <TabButton
                          key={tab}
                          tab={tab}
                          activeTab={activeTab}
                          onClick={() => handleSetActiveTab(tab)}
                        />
                        ))}
                    </Box>
                    <ClickAwayListener onClickAway={() => {
                    if (searchQuery.trim() === "") {
                      setShowSearch(false);
                    }
                    }}>
                      <Box ml={2}>
                        {showSearch ? (
                          <TextField
                            size="small"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleSearchChange(e.target.value);
                            }}
                            placeholder={t("Search...")}
                            inputRef={searchRef}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Tooltip title={t("Search")}>
                            <IconButton 
                              aria-label="Search" 
                              onClick={() => setShowSearch(true)}
                              size="small"
                              sx={{
                                backgroundColor: 'white',
                                color: '#1c4ed8',
                                borderColor: '#1c4ed8',
                                border: '1px solid',
                                alignItems: "center",
                              }}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </ClickAwayListener>

                    <FilterMenu onFilterChange={handleFilterChange} t={t} i18n={i18n} />

                    <Tooltip title={t("More options")}>
                      <IconButton
                        aria-label="More options"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem 
                        onClick={handleDelete} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        {deleteLoading ? <CircularProgress size={20} style={{ color: "#1c4ed8" }} /> : null}
                        <DeleteIcon sx={{ mr: 1 }} />
                        {t("Delete")}
                      </MenuItem>
                      <MenuItem 
                        onClick={handleDownloadClick} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        <DownloadIcon sx={{ mr: 1 }} />
                        {t("Download")}
                      </MenuItem>
                      <MenuItem 
                        onClick={handleEmailClick} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        <EmailIcon sx={{ mr: 1 }} />
                        {t("Email Invoice")}
                      </MenuItem>
                      <MenuItem 
                        onClick={() => {
                          setAnchorEl(null);
                          setShowExportFrame(true);
                        }}
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        <SyncIcon sx={{ mr: 1 }} />
                        {t("Export")}
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box display="flex" justifyContent="right" alignItems="center">
                    <Box 
                        justifyContent="right" 
                        width={"100%"} 
                      >
                      <Button
                          variant="contained"
                          size="small"
                          startIcon={<AddIcon />}
                          className="button"
                          onClick={handleAddClick}
                          sx={{ borderRadius: '50px'}}
                        >
                          {t("Create Invoice")}
                        </Button>
                      </Box>
                    </Box>
                </Box>

                {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
                {showDownloadFrame && <DownloadFrame 
                                        onClose={handleCloseDownloadFrame} 
                                        checkedInvoiceIds={checkedInvoiceIds}
                                        url={url} />}
                {showAddFrame && <AddFrame onClose={handleCloseAddFrame} />}

                {showExportFrame && (
                  <ExportFrame
                    onClose={(success) => {
                      setShowExportFrame(false);
                      if (success) {
                        setStatus({
                          severity: 'success',
                          message: t('Successfully exported invoice(s)!')
                        });
                      }
                    }}
                    invoiceIds={checkedInvoiceIds}
                    connections={[
                      {
                        name: 'Xero',
                        icon: <img src={XeroLogo} alt="Xero" style={{ width: 40, height: 40 }} />,
                        status: xeroIntegrationStatus || 'disconnected'
                      },
                      {
                        name: 'QuickBooks',
                        icon: <img src={QuickBooksLogo} alt="QuickBooks" style={{ width: 40, height: 40 }} />,
                        status: quickbooksIntegrationStatus || 'disconnected'
                      },
                      {
                        name: 'Sage',
                        icon: <img src={SageLogo} alt="Sage" style={{ width: 40, height: 40 }} />,
                        status: sageIntegrationStatus || 'disconnected'
                      }
                    ]}
                  />
                )}

                {/* Location Change Modal */}
                <Dialog
                  open={isLocationModalOpen}
                  onClose={handleCloseLocationModal}
                  aria-labelledby="location-selection-dialog-title"
                  aria-describedby="location-selection-dialog-description"
                  maxWidth="xs"
                  fullWidth
                  PaperProps={{
                    sx: {
                      borderRadius: '24px',
                      padding: theme.spacing(2),
                      textAlign: 'center',
                      backgroundColor: theme.palette.background.default,
                      boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                      position: 'relative'
                    }
                  }}
                >
                  <DialogContent sx={{ pt: theme.spacing(4), pb: theme.spacing(2) }}>
                  <IconButton
                    size="large"
                    sx={{
                      position: "absolute",
                      top: 18,
                      right: 18,
                      backgroundColor: "white",
                      "&:hover": { backgroundColor: "#f0f4ff" },
                    }}
                    onClick={handleCloseLocationModal}
                  >
                    <CloseIcon />
                  </IconButton>
                    <Box 
                      display="flex" 
                      flexDirection="column" 
                      alignItems="center" 
                      justifyContent="center"
                      gap={3}
                    >
                      <LocationOnIcon 
                        sx={{ 
                          color: '#1c4ed8', 
                          fontSize: '3rem',
                          mb: 1,
                          filter: 'drop-shadow(0 4px 6px rgba(0,0,0,0.1))'
                        }} 
                      />
                      
                      <Typography 
                        variant="h5" 
                        id="location-selection-dialog-title"
                        sx={{ 
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                          mb: 1
                        }}
                      >
                        {t("Select Your Location")}
                      </Typography>
                      
                      <Typography 
                        variant="body1"
                        sx={{ 
                          color: theme.palette.text.secondary,
                          mb: 2,
                          textAlign: 'center'
                        }}
                      >
                        {t("Choose the location where you'll be using our service")}
                      </Typography>
                      
                      <FormControl 
                        fullWidth 
                        variant="outlined" 
                        sx={{ 
                          mb: 2,
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '16px',
                            '& fieldset': {
                              borderColor: theme.palette.divider
                            },
                            '&:hover fieldset': {
                              borderColor: '#1c4ed8'
                            },
                          }
                        }}
                      >
                        <InputLabel id="location-select-label">
                          {t("Location")}
                        </InputLabel>
                        <Select
                          labelId="location-select-label"
                          id="location-select"
                          value={selectedLocation}
                          label={t("Location")}
                          onChange={handleLocationChange}
                          fullWidth
                          sx={{
                            borderRadius: '16px',
                            '& .MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              gap: theme.spacing(1)
                            }
                          }}
                        >
                          {availableLocations
                            .filter(location => location.address.trim() !== '') // Filters out empty or whitespace-only locations
                            .map(location => (
                              <MenuItem key={location.address} value={location.address}>
                                <LocationOnIcon sx={{ mr: 1 }} />
                                {location.address}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </DialogContent>
                  
                  <DialogActions 
                    sx={{ 
                      justifyContent: 'center', 
                      gap: 2, 
                      pb: theme.spacing(3),
                      px: theme.spacing(3),
                      flexDirection: 'column' // Keep column layout
                    }}
                  >
                    <Box 
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%'
                      }}
                    >
                      <Button 
                        onClick={handleCloseLocationModal} 
                        variant="outlined"
                        sx={{
                          color: '#1c4ed8',
                          borderColor: '#1c4ed8',
                          borderRadius: '50px',
                          px: theme.spacing(5),
                          py: theme.spacing(1)
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                      
                      <Button 
                        onClick={handleConfirm}
                        variant="contained"
                        disabled={!selectedLocation}
                        startIcon={
                          isLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckCircleOutlineIcon />
                          )
                        }
                        sx={{
                          backgroundColor: '#1c4ed8',
                          borderRadius: '50px',
                          px: theme.spacing(3),
                          py: theme.spacing(1),
                          boxShadow: 'none',
                          '&:hover': {
                            boxShadow: 'none'
                          }
                        }}
                      >
                        {t("Confirm")}
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', my: theme.spacing(2) }}>
                      <Divider 
                        sx={{
                          flexGrow: 1, 
                          borderColor: theme.palette.divider,
                          mr: theme.spacing(2)
                        }} 
                      />
                      <Typography 
                        variant="body2" 
                        color="textSecondary"
                        sx={{
                          color: theme.palette.text.secondary
                        }}
                      >
                        {t("Or")}
                      </Typography>
                      <Divider 
                        sx={{
                          flexGrow: 1, 
                          borderColor: theme.palette.divider,
                          ml: theme.spacing(2)
                        }} 
                      />
                    </Box>
                    <Button 
                      variant="contained"
                      size="small"
                      className="button"
                      startIcon={<MapPinPlus color="white" size="20px"/>}
                      onClick={() => {
                        handleCloseLocationModal()
                        setIsAddBusinessModalOpen(true)
                      }}
                      sx={{
                        backgroundColor: '#1c4ed8',
                        borderRadius: '50px',
                        px: theme.spacing(3),
                        py: theme.spacing(1),
                        width: '100%', // Full width
                      }}
                    >
                      {t("Add New Business")}
                    </Button>
                  </DialogActions>
                </Dialog>
                

                <motion.div {...fadeIn}>
                  <DataGrid
                    rows={invoices}
                    columns={columns}
                    checkboxSelection
                    onRowClick={handleRowClick}
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setCheckedInvoiceIds(newSelectionModel);
                    }}
                    sx={{
                      height: '65vh',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                    rowHeight={70}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={i18n.language !== 'en' ? plPL.components.MuiDataGrid.defaultProps.localeText : {}}
                    slots={{
                      noRowsOverlay: () => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          {t("No invoices to display.")}
                        </Box>
                      ),
                    }}             
                  />
                </motion.div>
              </Box>
            </Container>
          </Box>
        </Stack>
      </Box>
      {showEmailModal && (
        <EmailInvoiceModal
          open={showEmailModal}
          onClose={() => setShowEmailModal(false)}
          invoiceIds={checkedInvoiceIds}
          url={url}
        />
      )}
    </Box>
  );
};
