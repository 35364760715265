import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import "./style.css";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import useAxios from "../../utils/useAxios";

export const DownloadFrame = ({ onClose, checkedInvoiceIds, url }) => {
  const demo = !localStorage.getItem("authTokens");
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [selectedFormat, setSelectedFormat] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState('');
  const axios = useAxios();

  // Function to fetch invoice details and items
  const fetchInvoiceItems = useCallback(async (invoiceId) => {
    if (demo) {
      try {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/items`);
        if (!response.ok) {
          throw new Error(`Failed to fetch items for invoice ${invoiceId}`);
        }
        const data = await response.json();
        return data.data || [];
      } catch (error) {
        console.error(`Error fetching items for invoice ${invoiceId}:`, error);
        throw error;
      }
    } else {
      try {
        const response = await axios.get(`${url}/api/invoices/${invoiceId}/items`);
        return response.data.data || [];
      } catch (error) {
        console.error(`Error fetching items for invoice ${invoiceId}:`, error);
        throw error;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, demo]);

  // Convert data to CSV format
  const convertToCSV = (data) => {
    if (!data.length) return '';
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(item => 
      Object.values(item).map(val => 
        typeof val === 'string' ? `"${val}"` : val
      ).join(',')
    ).join('\n');
    return header + rows;
  };

  // Convert data to XLSX format
  const convertToXLSX = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Invoice Items');
    return XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  };

  // Generate filename for a single invoice
  const generateFilename = (invoiceName, format) => {
    const basename = invoiceName.replace(/^.*[\\/]/, '');
    const timestamp = new Date().toISOString().split('T')[0];
    const sanitizedName = basename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    return `${sanitizedName}_${timestamp}.${format.toLowerCase()}`;
  };

  // Download a single invoice's data
  const downloadInvoiceData = async (invoiceData) => {
    const invoiceName = invoiceData.invoice_name || invoiceData.invoice_id_number;
    const items = invoiceData;

    if (items.length === 0) {
      setProgress(t('noItemsFoundForInvoice', { invoiceName }));
      return;
    }

    const filename = generateFilename(invoiceName, selectedFormat);
    let blob;

    if (selectedFormat === 'CSV') {
      const csvContent = convertToCSV(items);
      blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    } else if (selectedFormat === 'XLSX') {
      const allItems = await Promise.all(
        checkedInvoiceIds.map(id => fetchInvoiceItems(id))
      );
      const xlsxContent = convertToXLSX(allItems);
        blob = new Blob([xlsxContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      }

      saveAs(blob, filename);
      setProgress(t('downloaded', { filename }));
  };

  // Handle format selection
  const handleFormatChange = (format) => {
    setSelectedFormat(format === selectedFormat ? '' : format);
  };

  // Main download function
  const handleDownload = async () => {
    if (!selectedFormat) {
      setError(t('Please select a format'));
      return;
    }

    setIsLoading(true);
    setError('');
    setProgress(t('Starting downloads...'));

    try {
      // Process each invoice one at a time
      for (const invoiceId of checkedInvoiceIds) {
        setProgress(`Fetching data for invoice ${invoiceId}...`);
        const invoiceData = await fetchInvoiceItems(invoiceId);
        await downloadInvoiceData(invoiceData);
        // Small delay between downloads to prevent overwhelming the browser
        await new Promise(resolve => setTimeout(resolve, 500));
      }

      setProgress(t('All downloads completed'));
      setTimeout(onClose, 500); // Close after showing completion message
    } catch (error) {
      console.error('Download error:', error);
      setError(t('Failed to download some items. Please try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="download-backdrop" onClick={onClose} />
      <div className="download-frame">
        <div className="content-wrapper">
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: 18,
              right: 18,
              border: 1,
              borderColor: '#1c4ed8',
              color: '#1c4ed8',
              backgroundColor: 'white',
              '&:hover': { backgroundColor: '#f0f4ff' },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          <Box mb={2} textAlign="center">
            <Typography
              variant="h6"
              marginTop="10px"
              fontWeight="bold"
              fontSize="1.5rem"
              color="#1c4ed8"
            >
              {t("Export Results")}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" gap={3} p={3}>
            <Typography variant="body1" color="#49454f" fontSize="15px">
              {t("Choose download format")}
            </Typography>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox 
                    icon={<FileDownloadIcon />} 
                    checkedIcon={<FileDownloadIcon />}
                    checked={selectedFormat === 'CSV'}
                    onChange={() => handleFormatChange('CSV')}
                  />
                }
                label="CSV"
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    icon={<FileDownloadIcon />} 
                    checkedIcon={<FileDownloadIcon />}
                    checked={selectedFormat === 'XLSX'}
                    onChange={() => handleFormatChange('XLSX')}
                  />
                }
                label="XLSX"
              />
            </FormGroup>

            {error && (
              <Typography color="error" fontSize="14px">
                {error}
              </Typography>
            )}

            {progress && (
              <Typography color="primary" fontSize="14px" textAlign="center">
                {progress}
              </Typography>
            )}

            <Button
              variant="contained"
              disabled={isLoading || !selectedFormat}
              onClick={handleDownload}
              sx={{
                borderRadius: '50px',
                mt: 2,
                color: 'white',
                backgroundColor: '#1c4ed8',
                padding: '10px 20px',
                '&:hover': { backgroundColor: '#163bb7' },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('downloadFiles', { count: checkedInvoiceIds.length })
              )}
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};




