import React, { useState } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionsDialog } from '../../pages/SignUp/TermsAndConditionsDialog';

export const Footer = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [openTerms, setOpenTerms] = useState(false);
    const isHomePage = location.pathname === '/' || 
                      location.pathname.startsWith('/pricing') || 
                      location.pathname.startsWith('/demo') || 
                      location.pathname.startsWith('/contact') || 
                      location.pathname.startsWith('/login') || 
                      location.pathname.startsWith('/register') || 
                      location.pathname.startsWith('/verify');

    const handleTermsClick = (e) => {
        e.preventDefault();
        setOpenTerms(true);
    };

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    if (!isHomePage) {
        return (
            <Typography
              variant="body2"
              align="center"
              sx={[
                {
                  color: 'text.secondary',
                },
                { my: 4 },
              ]}
            >
               Copyright ©
              <Link color="inherit" href="https://mui.com/">
                YourDraft
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          );
    }
    else {
        return (
            <>
                <Box 
                    component="footer" 
                    sx={{
                        py: 4,
                        backgroundColor: location.pathname === '/' ? '#e0f2fe' : 'white',
                        color: 'black'
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                fontWeight: '500'
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: '500' }}>
                                YourDraft ©{new Date().getFullYear()}
                            </Typography>
                            
                            <Box sx={{ display: 'flex', gap: 3 }}>
                                <Link href="/login" color="inherit" underline="none">{t('Start Now')}</Link>
                                <Link href="/pricing" color="inherit" underline="none">{t('Pricing')}</Link>
                                <Link href="/demo" color="inherit" underline="none">{t('How It Works')}</Link>
                                <Link 
                                    href="/terms" 
                                    color="inherit" 
                                    underline="none"
                                    onClick={handleTermsClick}
                                >
                                    {t('Terms of Service')}
                                </Link>
                            </Box>
                        </Box>
                    </Container>
                </Box>

                {openTerms && (
                    <TermsAndConditionsDialog 
                        open={openTerms} 
                        onClose={handleCloseTerms} 
                    />
                )}
            </>
        );
    }
}; 