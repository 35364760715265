import React from "react";
import { MenuItem, Select, FormControl, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", label: "EN", flag: "🇺🇸" },
    { code: "pl", label: "PL", flag: "🇵🇱" },
  ];

  // Get the normalized language code
  const language = i18n.language.split('-')[0];

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); // Change the language in i18n
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "30px",
          borderColor: 'white',
          "&:hover": {
          },
        },
        "& .MuiSelect-icon": {
          color: "#1c4ed8",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none", // Remove default border for custom styling
        },
        gap: 0,
      }}
    >
      <Select
        value={language}
        onChange={handleLanguageChange}
        displayEmpty
        inputProps={{
          "aria-label": "Select language",
        }}
        sx={{
          color: "black",
          borderColor: 'white',
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
              overflow: "hidden",
              borderColor: 'white',
            },
          },
        }}
      >
        {languages.map(({ code, label, flag }) => (
          <MenuItem
            key={code}
            value={code}
            sx={{
              display: "flex",
              alignItems: "center",
              "&:hover": {
                backgroundColor: alpha("#1c4ed8", 0.1),
              },
              borderColor: 'white',
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
