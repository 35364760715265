import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Chip,
  Button,
  CircularProgress,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem
} from '@mui/material';
import { X, Settings } from 'lucide-react';
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


// Add this new component before the Dashboard component
export const ExportFrame = ({ onClose, invoiceIds, connections }) => {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const axios = useAxios();
  
  // State to store accounts for each connection
  const [connectionAccounts, setConnectionAccounts] = useState({});
  
  // Initialize selectedAccounts with empty defaults
  const [selectedAccounts, setSelectedAccounts] = useState(
    connections.reduce((acc, connection) => ({
      ...acc,
      [connection.name]: ''
    }), {})
  );

  // Add these new states
  const [exportStatus, setExportStatus] = useState({});
  const [exportLoading, setExportLoading] = useState({});

  // Add new loading state
  const [accountsLoading, setAccountsLoading] = useState({});

  // Fetch accounts for each connection
  useEffect(() => {
    const fetchAccounts = async (connection) => {
      setAccountsLoading(prev => ({ ...prev, [connection.name]: true }));
      try {
        const response = await axios.get(`${url}/api/integration/${connection.name.toLowerCase()}/connections/`);
        if (response.data.requires_auth) {
            navigate(`/membership?tab=integration&requires_auth=${connection.name}`);    
          return;
        }
        // Transform the response to only include tenantName as name and tenantId as id
        const transformedAccounts = response.data.map(account => ({
          id: connection.name === 'Xero' ? account.tenantId : connection.name === 'Quickbooks' ? account.CompanyInfo?.Id : account.CompanyInfo?.Id,
          name: connection.name === 'Xero' ? account.tenantName : connection.name === 'Quickbooks' ? account.CompanyInfo?.CompanyName : account.CompanyInfo?.CompanyName
        }));
        
        setConnectionAccounts(prev => ({
          ...prev,
          [connection.name]: transformedAccounts
        }));
        
        // Set the first account as default if available
        if (transformedAccounts.length > 0) {
          setSelectedAccounts(prev => ({
            ...prev,
            [connection.name]: transformedAccounts[0].name
          }));
        }
      } catch (error) {
        console.error(`Failed to fetch accounts for ${connection.name}:`, error);
      } finally {
        setAccountsLoading(prev => ({ ...prev, [connection.name]: false }));
      }
    };

    connections.forEach(connection => {
      if (connection.status === 'connected') {
        fetchAccounts(connection);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections]);

  const handleAccountChange = (connectionName, accountName) => {
    setSelectedAccounts(prev => ({
      ...prev,
      [connectionName]: accountName
    }));
  };

  const handleExport = async (connectionName, accountName) => {
    try {
      setExportLoading(prev => ({ ...prev, [connectionName]: true }));
      const account = connectionAccounts[connectionName]?.find(acc => acc.name === accountName);
      const accountId = account?.id;

      const response = await axios.post(`${url}/api/integration/${connectionName.toLowerCase()}/export/`, {
        invoice_ids: invoiceIds,
        account: accountId
      });
      
      if (response.status === 200) {
        setExportStatus(prev => ({ 
          ...prev, 
          [connectionName]: { type: 'success', message: t('Export completed successfully') }
        }));
        // Clear success message after 3 seconds
        setTimeout(() => {
          setExportStatus(prev => ({ ...prev, [connectionName]: null }));
        }, 3000);
      }
    } catch (error) {
      setExportStatus(prev => ({ 
        ...prev, 
        [connectionName]: { type: 'error', message: t('Export failed. Please try again.') }
      }));
    } finally {
      setExportLoading(prev => ({ ...prev, [connectionName]: false }));
    }
  };

  const handleGoToSettings = () => {
    // Implement the logic to navigate to the settings page
    navigate('/membership?tab=integration');
  };

  return (
    <Dialog
      open={true}
      onClose={() => onClose(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '24px',
          padding: 3
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <IconButton
          onClick={() => onClose(false)}
          sx={{
            position: 'absolute',
            right: -8,
            top: -8,
            color: 'text.secondary'
          }}
        >
          <X />
        </IconButton>
        
        <Typography variant="h6" sx={{ mb: 3, color: '#1c4ed8' }}>
          {t("Export Settings")}
        </Typography>

        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Connection Name")}</TableCell>
                <TableCell>{t("Account Name")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connections.map((connection) => (
                <TableRow key={connection.name}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {connection.icon}
                      <Typography>{connection.name}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={selectedAccounts[connection.name] || ''}
                      onChange={(e) => handleAccountChange(connection.name, e.target.value)}
                      disabled={connection.status !== 'connected'}
                      fullWidth
                      sx={{ borderRadius: 2 }}
                      startAdornment={
                        accountsLoading[connection.name] ? (
                          <CircularProgress size={30} sx={{ mr: 1 }} />
                        ) : null
                      }
                    >
                      {connectionAccounts[connection.name]?.map((account) => (
                        <MenuItem key={account.id} value={account.name}>{account.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={connection.status === 'connected' ? t('Active') : t('Not Active')}
                      color={connection.status === 'connected' ? 'success' : 'default'}
                      size="large"
                    />
                    <IconButton size="small" sx={{ marginLeft: 1 }}>
                        <Settings size={18} onClick={handleGoToSettings}/>
                      </IconButton>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ 
                          borderRadius: '16px', 
                          backgroundColor: connection.status !== 'connected' ? '' : '#1c4ed8', 
                          color: connection.status !== 'connected' ? 'black' : 'white', 
                          textTransform: 'none',
                          minWidth: '100px',
                          position: 'relative'
                        }}
                        onClick={() => handleExport(connection.name, selectedAccounts[connection.name])}
                        disabled={connection.status !== 'connected' || exportLoading[connection.name]}
                      >
                        {exportLoading[connection.name] ? (
                          <CircularProgress size={20} sx={{ color: 'white' }} />
                        ) : t("Export")}
                      </Button>
                      {exportStatus[connection.name] && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: exportStatus[connection.name].type === 'success' ? 'success.main' : 'error.main',
                            animation: 'fadeIn 0.3s ease-in',
                            '@keyframes fadeIn': {
                              '0%': { opacity: 0 },
                              '100%': { opacity: 1 },
                            },
                          }}
                        >
                          {exportStatus[connection.name].message}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dialog>
  );
};