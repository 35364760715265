import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';

const InvoiceVendorAnalysis = ({ data }) => {
  const theme = useTheme();

  // Transform the data from [["VENDOR_NAME", count], ...] format
  const chartData = data.map(([vendor, count]) => ({
    vendor,
    invoiceCount: count
  }));

  const totalInvoices = chartData.reduce((sum, vendor) => sum + vendor.invoiceCount, 0);

  return (
    <Card variant="outlined" sx={{ width: '615px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold" gutterBottom>
          Top Vendor Analysis
        </Typography>
        <Stack sx={{ justifyContent: 'space-between', mb: 2 }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {totalInvoices} Invoices
            </Typography>
            <Chip size="small" color="success" label="+X% (Last Month)" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Summary of invoice count by top vendors
          </Typography>
        </Stack>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            layout="vertical"
            margin={{ top: 20, right: 20, left: 40, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="vendor" type="category" width={100} />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="invoiceCount"
              name="Invoice Count"
              fill={(theme.vars || theme).palette.success.main}
              barSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

InvoiceVendorAnalysis.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default InvoiceVendorAnalysis;


