import React, { useState, useCallback } from 'react';
import { FileText} from 'lucide-react';
import { Box, Container, Typography, Button, Skeleton, Stack, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { Navbar } from "../../components/Navbar/Navbar";
import SideBar from "../../components/SideBar/SideBar";
import { DropZone } from '../../components/ReceiptConverter/Dropzone';
import { ImagePreview } from '../../components/ReceiptConverter/ImagePreview';
import { InvoicePreview } from '../../components/ReceiptConverter/InvoicePreview';
import { PDFViewer } from '../../components/ReceiptConverter/PDFViewer';
import { InvoiceActions } from '../../components/ReceiptConverter/InvoiceActions';
import { generateInvoicePDF, ocr } from '../../utils/receiptUtils';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import useAxios from '../../utils/useAxios';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';

// Add LoadingPreview component definition within the same file
const LoadingPreview = () => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      sx={{
        bgcolor: 'background.paper',
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        position: 'relative',
        minHeight: '500px'
      }}
    >
      {/* Store Info Loading */}
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Skeleton variant="rectangular" width="60%" height={32} sx={{ mx: 'auto', mb: 1 }} />
        <Skeleton variant="text" width="40%" sx={{ mx: 'auto' }} />
        <Skeleton variant="text" width="30%" sx={{ mx: 'auto' }} />
      </Box>

      {/* Items Loading */}
      {[...Array(5)].map((_, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}
        >
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="20%" />
        </Box>
      ))}

      {/* Total Loading */}
      <Box sx={{ mt: 9, display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="20%" />
      </Box>

      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'text.secondary'
        }}
      >
        Extracting receipt data...
      </Typography>
    </Box>
  );
};

const GeneratingInvoiceLoader = () => {
  const steps = ['Processing receipt', 'Analysing Data', 'Formatting data', 'Almost done'];
  const [currentStep, setCurrentStep] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
    }, 2700);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        borderRadius: 2,
      }}
    >
      <motion.div
        animate={{
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <CircularProgress size={40} thickness={3} sx={{ color: '#1c4ed8' }} />
      </motion.div>
      
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h6" color="#1c4ed8" fontWeight="medium">
              {steps[currentStep]}
            </Typography>
          </motion.div>
        </AnimatePresence>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          This may take a few moments
        </Typography>
      </Box>
    </Box>
  );
};

const fadeIn = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

const ValidationDialog = ({ open, onClose, missingFields, formData, setFormData }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          minWidth: '400px',
        }
      }}
    >
      <DialogTitle sx={{ 
        bgcolor: '#1c4ed8', 
        color: 'white',
        py: 2
      }}>
        Complete Required Information
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Please fill in the following required fields:
        </Typography>
        <Stack spacing={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {missingFields.includes('invoiceIdNumber') && (
              <TextField
                label="Invoice ID"
                value={formData.invoiceIdNumber || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, invoiceIdNumber: e.target.value }))}
                fullWidth
              />
            )}
            {missingFields.includes('invoiceDate') && (
              <DatePicker
                label="Invoice Date"
                value={formData.invoiceDate || null}
                onChange={(date) => setFormData(prev => ({ ...prev, invoiceDate: date }))}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
            {missingFields.includes('dueDate') && (
              <DatePicker
                label="Due Date"
                value={formData.dueDate || null}
                onChange={(date) => setFormData(prev => ({ ...prev, dueDate: date }))}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
            {missingFields.includes('customerName') && (
              <TextField
                label="Customer Name"
                value={formData.customerName || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, customerName: e.target.value }))}
                fullWidth
              />
            )}
            {missingFields.includes('customerAddress') && (
              <TextField
                label="Customer Address"
                value={formData.customerAddress || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, customerAddress: e.target.value }))}
                fullWidth
                multiline
                rows={3}
              />
            )}
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button 
          onClick={onClose} 
          variant="outlined"
          sx={{ borderRadius: 2 }}
        >
          Cancel
        </Button>
        <Button 
          onClick={() => onClose(true)} 
          variant="contained"
          sx={{ 
            borderRadius: 2,
            bgcolor: '#1c4ed8',
            '&:hover': {
              bgcolor: '#1e40af'
            }
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ReceiptConverter = () => {
  const demo = !localStorage.getItem("authTokens");
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  const [openSideBar, setOpenSideBar] = useState(true);
  const [image, setImage] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isExtracting, setIsExtracting] = useState(false);
  const [extractedData, setExtractedData] = useState('');
  const [isAnalysingData, setIsAnalysingData] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [response, setResponse] = useState(null);
  const axios = useAxios();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [parsedData, setParsedData] = useState(null);

  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleFilesDrop = useCallback(async (files) => {
    if (files.length === 0) return;
    
    const file = files[0];
    const preview = URL.createObjectURL(file);
    setImage({ file, preview });
    
    // Start OCR process
    setIsExtracting(true);
    try {
      const data = await ocr({
        filePath: file,
        axios,
      });
      setExtractedData(data);
    } catch (error) {
      console.error('OCR extraction failed:', error);
      // Show error message
    } finally {
      setIsExtracting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRerun = async () => {
    setIsExtracting(true);
      
    const file = image.file;
    
    // Start OCR process
    try {
      const data = await ocr({
        filePath: file,
        apiKey: process.env.REACT_APP_TOGETHER_API_KEY,
      });
      setExtractedData(data);
    } catch (error) {
      console.error('OCR extraction failed:', error);
      // Show error message
    } finally {
      setIsExtracting(false);
    }
  };


  const handleRemoveImage = () => {
    if (image?.preview) {
      URL.revokeObjectURL(image.preview);
    }
    setImage(null);
    setExtractedData(null);
    setPdfUrl(null);
  };

  const handleGeneratePDF = async () => {
    if (!extractedData) return;
    
    await generatePDF();
  };

  // Update the generatePDF function
  const generatePDF = async () => {
    setIsAnalysingData(true);
    try {
      // First attempt to generate PDF with the extracted data
      const response = await generateInvoicePDF(extractedData, axios, demo);
      
      // If we get missing fields in the response, show the dialog
      if (response.missingFields) {
        setParsedData(response);
        setMissingFields(response.missingFields);
        setDialogOpen(true);
        return;
      }

      // If no missing fields, proceed with setting the response and PDF URL
      setResponse(response);
      const pdfUrl = `${url}${response.data.file}`;
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsAnalysingData(false);
    }
  };

  const handleDialogClose = (shouldContinue) => {
    setDialogOpen(false);
    if (shouldContinue && parsedData) {
      // Merge the form data with the parsed data
      const mergedData = {
        ...parsedData.parsedReceiptData,
        ...formData
      };
      
      // Check if all required fields are now filled
      const required = ['invoiceIdNumber', 'invoiceDate', 'dueDate', 'customerName', 'customerAddress'];
      const stillMissing = required.filter(field => !mergedData[field]);
      
      if (stillMissing.length === 0) {
        setIsGeneratingPDF(true);
        const skipParsing = true
        // Process with complete data
        generateInvoicePDF(mergedData, axios, demo, skipParsing, parsedData.parsedReceiptItems )
          .then(response => {
            setResponse(response);
            const pdfUrl = `${url}${response.data.file}`;
            setPdfUrl(pdfUrl);
          })
          .catch(error => {
            console.error('Error generating PDF:', error);
          })
          .finally(() => {
            setIsGeneratingPDF(false);
          });
      }
    }
  };

  return (
    <Box width="100%" bgcolor="white"
    sx={{
      backgroundImage:
        'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      '&.dark': {
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
      },
    }}>
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} />
      <Navbar sideBarOpen={openSideBar} />
      
      <Box component="main" sx={{overflow: 'auto', flexGrow: 1 }}>
      <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        > 
        <Box sx={{ width: openSideBar ? '100%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
          <Container sx={{ mt: 14}}>
            <motion.div {...fadeIn}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <FileText size={48} color="#1c4ed8" />
                  <Box>
                    <Typography variant="h6" fontWeight="bold" color="black">
                     Receipt To Invoice Generator
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Drop your receipt image to generate an invoice
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {image === null && <DropZone onFilesDrop={handleFilesDrop} />}

              {image !== null && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: -2}}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
                    <Box>
                      <ImagePreview image={image} onRemove={handleRemoveImage} />
                      {!pdfUrl && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, position: 'relative' }}>
                          {isAnalysingData && <GeneratingInvoiceLoader />}
                          <Button
                            variant="contained"
                            disabled={isExtracting || isAnalysingData}
                            onClick={handleGeneratePDF}
                            startIcon={ isGeneratingPDF ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null }
                            sx={{ 
                              mt: 2, 
                              py: 1,
                              px: 4,
                              borderRadius: '25px', 
                              bgcolor: '#1c4ed8', 
                              color: 'white', 
                              textTransform: 'none'
                            }}
                          >
                            Generate Invoice
                          </Button>
                          <Button
                            variant="contained"
                            color="success"
                            disabled={isExtracting || isAnalysingData}
                            onClick={handleRerun}
                            startIcon={<FastForwardOutlinedIcon />}
                            sx={{ 
                              mt: 2, 
                              py: 1,
                              px: 4,
                              borderRadius: '25px', 
                              textTransform: 'none'
                            }}
                          >
                            Rerun
                          </Button>
                        </Box>
                      )}
                      {pdfUrl && (
                        <InvoiceActions invoice={response} onSave={handleRemoveImage} />
                      )}
                    </Box>

                    <Box>
                      {isExtracting ? (
                        <LoadingPreview />
                      ) : (
                        pdfUrl ? (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <PDFViewer pdfUrl={pdfUrl} />
                          </Box>
                        ) : (
                          <InvoicePreview extractedText={extractedData} />
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </motion.div>
          </Container>
        </Box>
        </Stack>
      </Box>
      <ValidationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        missingFields={missingFields}
        formData={formData}
        setFormData={setFormData}
      />
    </Box>
  );
};