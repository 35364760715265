import React from 'react';
import { Card, CardContent, Typography, Box, Stack, LinearProgress, linearProgressClasses } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const StyledText = styled('text')(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    {
      props: { variant: 'primary' },
      style: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
    {
      props: { variant: 'secondary' },
      style: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
  ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <>
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </>
  );
}

export default function InvoiceCategoryAnalysis({ data = [] }) {
  // Format data for the chart
  const chartData = data?.map(item => ({
    label: item.category,
    value: item.count
  })) || [];

  const total = chartData.reduce((sum, item) => sum + item.value, 0);

  return (
    <Card variant="outlined" sx={{ width: '610px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold">
          Category Distribution
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PieChart
            colors={COLORS}
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                data: chartData,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 5,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={260}
            width={260}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel primaryText={`${total}`} secondaryText="Total Items" />
          </PieChart>
        </Box>
        <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
          {chartData.map((entry, index) => (
            <Stack key={index} direction="row" sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
              <Stack sx={{ gap: 1, flexGrow: 1 }}>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    {entry.label}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {((entry.value / total) * 100).toFixed(1)}%
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  aria-label={`Number of items ${entry.label}`}
                  value={(entry.value / total) * 100}
                  sx={{
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: COLORS[index % COLORS.length],
                    },
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
