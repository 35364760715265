import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItem, Typography, Box, TextField, Button, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';

// Function to transform AddressValue object into a plain JS object
const transformAddressValue = (addressValue, t) => {
  // eslint-disable-next-line no-unused-vars
  // Check if addressValue is a string and try to parse it
  if (typeof addressValue === 'string') {
    try {
      // Replace single quotes with double quotes to make it valid JSON
      addressValue = addressValue.replace(/'/g, '"');
      addressValue = JSON.parse(addressValue); // Parse the corrected string into an object
    } catch (error) {
      console.error('Failed to parse addressValue:', error);
      return {}; // Return empty object if parsing fails
    }
  }

  // Now process the object
  if (addressValue && typeof addressValue === 'object') {
    return {
      house_number: addressValue.house_number || '',
      road: addressValue.road || '',
      city: addressValue.city || '',
      state: addressValue.state || '',
      postal_code: addressValue.postal_code || '',
      street_address: addressValue.street_address || '',
      po_box: addressValue.po_box || '',
      country_region: addressValue.country_region || '',
      unit: addressValue.unit || '',
      city_district: addressValue.city_district || '',
      state_district: addressValue.state_district || '',
      suburb: addressValue.suburb || '',
      house: addressValue.house || '',
      level: addressValue.level || ''
    };
  } else {
    console.warn('Invalid addressValue:', addressValue);
    return {};
  }
};

const transformItems = (item, t) => {
  // Check if item is a string and try to parse it
  if (typeof item === 'string') {
    try {
      // Replace single quotes with double quotes to make it valid JSON
      item = item.replace(/'/g, '"');
      item = JSON.parse(item); // Parse the corrected string into an object
    } catch (error) {
      console.error('Failed to parse item:', error);
      return []; // Return empty array if parsing fails
    }
  }

  // Now process the object or array
  if (Array.isArray(item)) {
    return item.map(singleItem => {
      if (singleItem && typeof singleItem === 'object') {
        return {
          id: singleItem.id || '',
          item_description: singleItem.item_description || '',
          item_quantity: singleItem.item_quantity || '',
          unit: singleItem.unit || '',
          unit_price: singleItem.unit_price || '',
          product_code: singleItem.product_code || '',
          item_date: singleItem.item_date || '',
          tax: singleItem.tax || '',
          amount: singleItem.amount || '',
        };
      }
      console.warn('Invalid item:', singleItem);
      return {};
    });
  } else if (item && typeof item === 'object') {
    return [{
      id: item.id || '',
      item_description: item.item_description || '',
      item_quantity: item.item_quantity || '',
      unit: item.unit || '',
      unit_price: item.unit_price || '',
      product_code: item.product_code || '',
      item_date: item.item_date || '',
      tax: item.tax || '',
      amount: item.amount || '',
    }];
  } else {
    console.warn('Invalid item:', item);
    return [];
  }
};


const NestedItems = ({ item, onItemsRetrieved, changeMade, isItemsOpen, toggleItems}) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [editableIndex, setEditableIndex] = useState({ invoiceIndex: null, fieldIndex: null });
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (item) {
      const transformedItems = transformItems(item, t);

      const newFields = transformedItems.map((transformedItem) =>
        Object.entries(transformedItem)
          .map(([key, value]) => ({
            key, // Prefix the key with Invoice Item #
            value,
          }))
          .filter(({ value }) => value !== null && value !== '') // Filter out null/empty values
      );
      setItems(newFields);
    }
  }, [item, t]);


  const handleEditClick = (invoiceIndex, fieldIndex) => {
    setEditableIndex({ invoiceIndex, fieldIndex });
    setEditValue(items[invoiceIndex][fieldIndex].value);
  };

  const handleSave = () => {
    const { invoiceIndex, fieldIndex } = editableIndex;
    const newItems = [...items];
    newItems[invoiceIndex][fieldIndex].value = editValue; // Save the edited value
    setItems(newItems); // Update the items array
    onItemsRetrieved(newItems);
    setEditableIndex({ invoiceIndex: null, fieldIndex: null }); // Reset editable index
    changeMade(true);
  };

  const handleDelete = (invoiceIndex, fieldIndex) => {
    const newItems = [...items];
    newItems[invoiceIndex] = newItems[invoiceIndex].filter((_, i) => i !== fieldIndex); // Remove the item at the index
    setItems(newItems); // Update items state
    onItemsRetrieved(newItems);
    changeMade(true);
    // Reset the edit state if the deleted item was being edited
    if (editableIndex.invoiceIndex === invoiceIndex && editableIndex.fieldIndex === fieldIndex) {
      setEditableIndex({ invoiceIndex: null, fieldIndex: null });
      setEditValue('');
    }
  };

  const CollapsibleSection = ({ title, children, isOpen, onToggle }) => {
    return (
      <Box sx={{ }}>
        <Button
          onClick={onToggle}
          sx={{
            color: '#1c4ed8',
            width: '100%',
            justifyContent: 'left',
            padding: '12px 0',
            borderBottom: isOpen ? 'none' : '1px solid #1c4ed8',
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.7
            }
          }}
          startIcon={isOpen ? <ExpandLessIcon sx={{color: '#1c4ed8'}} /> : 
                      <ExpandMoreIcon sx={{color: '#1c4ed8'}} />}
        >
          <Typography variant="subtitle1" 
            sx={{ fontWeight: 500, textTransform: 'none', color: 'black' }}>
            {title}
          </Typography>
        </Button>
          {isOpen && (
          <Box >
            {children}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <List>
      <CollapsibleSection
        title={t("Invoice Items")}
        isOpen={isItemsOpen}
        onToggle={toggleItems}
      >
        {items.map((invoiceItemFields, invoiceIndex) => (
          <Box key={invoiceIndex} sx={{ marginBottom: 1 }}>
            <Typography variant="subtitle1" color='#1c4ed8' marginBottom="10px">
              {t("Item")} {invoiceIndex + 1}
            </Typography>

            {invoiceItemFields.map((item, fieldIndex) => (
              item.key !== 'id' && (
              <ListItem
                key={item.key}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderBottom: '1px solid #e0e0e0',
                  marginLeft: 2,
                }}
              >
                <Typography variant="body2" color="textSecondary" marginBottom="5px">
                  {t(item.key)}
                </Typography>

                {editableIndex.invoiceIndex === invoiceIndex && editableIndex.fieldIndex === fieldIndex ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      sx={{ flexGrow: 1 }}
                    />
                    <Button
                      onClick={handleSave}
                      sx={{ marginLeft: 2 }}
                    >
                      {t("Save")}
                    </Button>
                    <DeleteIcon
                      aria-label="delete"
                      sx={{ cursor: 'pointer', color: '#d32f2f', marginLeft: 2 }}
                      onClick={() => handleDelete(invoiceIndex, fieldIndex)}
                    />
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => handleEditClick(invoiceIndex, fieldIndex)}
                  >
                    {item.value}
                  </Typography>
                )}
              </ListItem>
            )))}
          </Box>
        ))}
      </CollapsibleSection>
    </List>
  );
};

// Nested Item List for address
const NestedAddress = ({ address, onItemsRetrieved, changeMade, title, isAddressOpen, toggleAddress }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (address) {
      const transformedAddress = transformAddressValue(address, t);
      const newFields = Object.entries(transformedAddress)
        .map(([key, value]) => ({ key, value }))
        .filter(({ value }) => value !== null && value !== ''); // Filter out null/empty values
      setItems(newFields);
    }
  }, [address, t]);

  const handleEditClick = (index) => {
    setEditableIndex(index);
    setEditValue(items[index].value);
  };

  const handleSave = () => {
    const newItems = [...items];
    newItems[editableIndex].value = editValue; // Save the edited value
    setItems(newItems); // Update the items array
    onItemsRetrieved(newItems);
    changeMade(true);
    setEditableIndex(null);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index); // Remove the item at the index
    setItems(newItems); // Update items state
    onItemsRetrieved(newItems);
    changeMade(true);
    if (editableIndex === index) {
      setEditableIndex(null);
      setEditValue('');
    }
  };

  const CollapsibleSection = ({ title, children, isOpen, onToggle }) => {
    return (
      <Box sx={{ }}>
        <Button
          onClick={onToggle}
          sx={{
            color: '#1c4ed8',
            width: '100%',
            justifyContent: 'left',
            padding: '12px 0',
            borderBottom: isOpen ? 'none' : '1px solid #1c4ed8',
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.7
            }
          }}
          startIcon={isOpen ? <ExpandLessIcon sx={{color: '#1c4ed8'}} /> : 
                      <ExpandMoreIcon sx={{color: '#1c4ed8'}} />}
        >
          <Typography variant="subtitle1" 
            sx={{ fontWeight: 500, textTransform: 'none', color: 'black' }}>
            {title}
          </Typography>
        </Button>
          {isOpen && (
          <Box >
            {children}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <List>
      <CollapsibleSection
        title={t(title)}
        isOpen={isAddressOpen}
        onToggle={toggleAddress}
      >
        {items.map((item, index) => (
          <ListItem
            key={item.key}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderBottom: '1px solid #e0e0e0',
              marginLeft: 2, // Optional: Indent nested items
            }}
          >
            <Typography variant="body2" color="textSecondary" marginBottom="5px">
              {t(item.key)}
            </Typography>
            {editableIndex === index ? (
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  sx={{ flexGrow: 1 }}
                />
                <Button onClick={handleSave} sx={{ marginLeft: 2 }}>
                  {t("Save")}
                </Button>
                <DeleteIcon
                  sx={{ cursor: 'pointer', color: '#d32f2f', marginLeft: 2 }}
                  onClick={() => handleDelete(index)}
                />
              </Box>
            ) : (
              <Typography
                variant="body1"
                sx={{ cursor: 'pointer', width: '100%' }}
                onClick={() => handleEditClick(index)}
              >
                {item.value}
              </Typography>
            )}
          </ListItem>
        ))}
      </CollapsibleSection>
    </List>
  );
};

const CustomFields = ({ customFields, onItemsRetrieved, changeMade, isCustomFieldsOpen, toggleCustomFields }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (customFields && (typeof customFields !== 'string' || customFields.trim() !== '')) {
      try {
        const parsedFields = typeof customFields === 'string' 
          ? JSON.parse(customFields) 
          : customFields;
        
        if (parsedFields && typeof parsedFields === 'object') {
          const newFields = Object.entries(parsedFields)
            .map(([key, value]) => ({ key, value }))
            .filter(({ value }) => value !== null && value !== '');
          setItems(newFields);
        } else {
          setItems([]);
        }
      } catch (error) {
        console.warn("Invalid custom fields format", error);
        setItems([]);
      }
    } else {
      setItems([]);
    }
  }, [customFields]);
  

  const handleEditClick = (index) => {
    setEditableIndex(index);
    setEditValue(items[index].value);
  };

  const handleSave = () => {
    const newItems = [...items];
    newItems[editableIndex].value = editValue; // Save the edited value
    setItems(newItems); // Update the items array
    onItemsRetrieved(newItems);
    changeMade(true);
    setEditableIndex(null);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index); // Remove the item at the index
    setItems(newItems); // Update items state
    onItemsRetrieved(newItems);
    changeMade(true);
    if (editableIndex === index) {
      setEditableIndex(null);
      setEditValue('');
    }
  };

  const CollapsibleSection = ({ title, children, isOpen, onToggle }) => {
    return (
      <Box sx={{ }}>
        <Button
          onClick={onToggle}
          sx={{
            color: '#1c4ed8',
            width: '100%',
            justifyContent: 'left',
            padding: '12px 0',
            borderBottom: isOpen ? 'none' : '1px solid #1c4ed8',
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.7
            }
          }}
          startIcon={isOpen ? <ExpandLessIcon sx={{color: '#1c4ed8'}} /> : 
                      <ExpandMoreIcon sx={{color: '#1c4ed8'}} />}
        >
          <Typography variant="subtitle1" 
            sx={{ fontWeight: 500, textTransform: 'none', color: 'black' }}>
            {title}
          </Typography>
        </Button>
          {isOpen && (
          <Box >
            {children}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <List>
      <CollapsibleSection
        title={t("Custom Fields")}
        isOpen={isCustomFieldsOpen}
        onToggle={toggleCustomFields}
      >
        {items.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderBottom: '1px solid #e0e0e0',
              marginLeft: 2, // Optional: Indent nested items
            }}
          >
            <Typography variant="body2" color="textSecondary" marginBottom="5px">
              {item.key}
            </Typography>
            {editableIndex === index ? (
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  sx={{ flexGrow: 1 }}
                />
                <Button onClick={handleSave} sx={{ marginLeft: 2 }}>
                  {t("Save")}
                </Button>
                <DeleteIcon
                  sx={{ cursor: 'pointer', color: '#d32f2f', marginLeft: 2 }}
                  onClick={() => handleDelete(index)}
                />
              </Box>
            ) : (
              <Typography
                variant="body1"
                sx={{ cursor: 'pointer', width: '100%' }}
                onClick={() => handleEditClick(index)}
              >
                {item.value}
              </Typography>
            )}
          </ListItem>
        ))}
      </CollapsibleSection>
    </List>
  );
};

// Main ItemList component
export const ItemList = ({ fields, setChangesMade, setModifiedFields }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [nestedItems, setNestedItems] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [allItems, setAllItems] = useState({})
  const [billingAddress, setBillingAddress] = useState(null); // Store the billing_address
  const [billingRecipient, setBillingRecipient] = useState(null); // Store the billing_address_recipient
  const [customerAddress, setCustomerAddress] = useState(null); // Store the customer_address
  const [customerRecipient, setCustomerRecipient] = useState(null); // Store the customer_address_recipient
  const [vendorAddress, setVendorAddress] = useState(null); // Store the vendor_address
  const [vendorRecipient, setVendorRecipient] = useState(null); // Store the vendor_address_recipient
  const [shippingAddress, setShippingAddress] = useState(null); // Store the shipping_address
  const [shippingRecipient, setShippingRecipient] = useState(null); // Store the shipping_address_recipient
  const [serviceAddress, setServiceAddress] = useState(null); // Store the service_address
  const [serviceRecipient, setServiceRecipient] = useState(null); // Store the service_address_recipient
  const [remittanceAddress, setRemittanceAddress] = useState(null); // Store the remittance_address
  const [remittanceRecipient, setRemittanceRecipient] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [isGeneralOpen, setIsGeneralOpen] = useState(true);
  const [isItemsOpen, setIsItemsOpen] = useState(false);
  const [isCustomFieldsOpen, setIsCustomFieldsOpen] = useState(false);
  const [isCustomerAddressOpen, setIsCustomerAddressOpen] = useState(false);
  const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);
  const [isVendorAddressOpen, setIsVendorAddressOpen] = useState(false);
  const [isShippingAddressOpen, setIsShippingAddressOpen] = useState(false);
  const [isServiceAddressOpen, setIsServiceAddressOpen] = useState(false);
  const [isRemittanceAddressOpen, setIsRemittanceAddressOpen] = useState(false);

  const toggleGeneral = () => setIsGeneralOpen(!isGeneralOpen);
  const toggleItems = () => {setIsItemsOpen(prev => !prev)};
  const toggleCustomFields = () => setIsCustomFieldsOpen(!isCustomFieldsOpen);
  const toggleCustomerAddress = () => setIsCustomerAddressOpen(!isCustomerAddressOpen);
  const toggleBillingAddress = () => setIsBillingAddressOpen(!isBillingAddressOpen);
  const toggleVendorAddress = () => setIsVendorAddressOpen(!isVendorAddressOpen);
  const toggleShippingAddress = () => setIsShippingAddressOpen(!isShippingAddressOpen);
  const toggleServiceAddress = () => setIsServiceAddressOpen(!isServiceAddressOpen);
  const toggleRemittanceAddress = () => setIsRemittanceAddressOpen(!isRemittanceAddressOpen);

  const handleItemsRetrieved = useCallback((retrievedItems, type) => {
    // Create a deep copy of allItems to prevent mutation issues
    const updatedAllItems = { ...allItems };
  
    // Logic to update specific type of items (e.g., invoice, billing, customer) in allItems
    if (type === 'invoice') {
      updatedAllItems.invoice = retrievedItems;
    } else if (type === 'invoice_items') {
      updatedAllItems.invoiceItems = retrievedItems;
    } else if (type === 'billing') {
      updatedAllItems.billingAddress = retrievedItems;
    } else if (type === 'customer') {
      updatedAllItems.customerAddress = retrievedItems;
    } else if (type === 'vendor') {
      updatedAllItems.vendorAddress = retrievedItems;
    } else if (type === 'service') {
      updatedAllItems.serviceAddress = retrievedItems;
    } else if (type === 'remittance') {
      updatedAllItems.remittanceAddress = retrievedItems;
    } else if (type === 'shipping') {
      updatedAllItems.shippingAddress = retrievedItems;
    } else if (type === 'custom_fields') {
      updatedAllItems.customFields = retrievedItems;
    }
    setAllItems(updatedAllItems); // Update the state with the new modified items
    setModifiedFields(updatedAllItems);
    // eslint-disable-next-line
  }, [allItems]);


  const changeMade = (value) => {
    if (value === true) {
      setChangesMade(true);
    }
  }

  const handleEditClick = (index) => {
    setEditableIndex(index);
    setEditValue(items[index].value);
  };

  const handleSave = () => {
    const newItems = [...items];
    newItems[editableIndex].value = editValue; // Save the edited value
    setItems(newItems); // Update the items array
    handleItemsRetrieved(newItems, 'invoice');
    changeMade(true);
    setEditableIndex(null); // Reset editable index
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index); // Remove the item at the index
    setItems(newItems); // Update items state
    handleItemsRetrieved(newItems, 'invoice');
    changeMade(true);
    if (editableIndex === index) {
      setEditableIndex(null);
      setEditValue('');
    }
  };

  const handleAddressUpdate = useCallback((fields, key, setAddress, setRecipient) => {
    const addressField = fields.find(field => field.key === key);
    const recipientField = fields.find(field => field.key === `${key}_recipient`);
  
    if (addressField) setAddress(addressField.value);
    if (recipientField) setRecipient(recipientField.value);
    
  }, []);

  const CollapsibleSection = ({ title, children, isOpen, onToggle }) => {
    return (
      <Box sx={{ }}>
        <Button
          onClick={onToggle}
          sx={{
            color: '#1c4ed8',
            width: '100%',
            justifyContent: 'left',
            padding: '12px 0',
            borderBottom: isOpen ? 'none' : '1px solid #1c4ed8',
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.7
            }
          }}
          startIcon={isOpen ? <ExpandLessIcon sx={{color: '#1c4ed8'}} /> : 
                      <ExpandMoreIcon sx={{color: '#1c4ed8'}} />}
        >
          <Typography variant="subtitle1" 
            sx={{ fontWeight: 500, textTransform: 'none', color: 'black' }}>
            {title}
          </Typography>
        </Button>
          {isOpen && (
          <Box >
            {children}
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (fields && fields.length > 0) {
      const invoiceFields = fields
        .filter(field => field.key !== 'invoice_name')
        .filter(field => field.key !== 'invoice_items')
        .filter(field => field.key !== 'billing_address')
        .filter(field => field.key !== 'customer_address')
        .filter(field => field.key !== 'vendor_address')
        .filter(field => field.key !== 'shipping_address')
        .filter(field => field.key !== 'service_address')
        .filter(field => field.key !== 'remittance_address')
        .filter(field => field.key !== 'custom_fields')
        .map(field => ({ key: field.key, value: field.value || '' }))
        .filter(field => field.value);
  
      setItems(invoiceFields);
      // handleItemsRetrieved(invoiceFields, 'invoice');
    }
  }, [fields]);

  useEffect(() => {
    const itemsField = fields.find(field => field.key === 'invoice_items');
    if (itemsField) {
      setNestedItems(itemsField.value);
    }
  }, [fields]);

  useEffect(() => {
    const customField = fields.find(field => field.key === 'custom_fields');
    if (customField) {
      setCustomFields(customField.value);
    }
  }, [fields]);

  useEffect(() => {
    if (fields && fields.length > 0) {      
      handleAddressUpdate(fields, 'billing_address', setBillingAddress, setBillingRecipient);
      handleAddressUpdate(fields, 'customer_address', setCustomerAddress, setCustomerRecipient);
      handleAddressUpdate(fields, 'vendor_address', setVendorAddress, setVendorRecipient);
      handleAddressUpdate(fields, 'shipping_address', setShippingAddress, setShippingRecipient);
      handleAddressUpdate(fields, 'service_address', setServiceAddress, setServiceRecipient);
      handleAddressUpdate(fields, 'remittance_address', setRemittanceAddress, setRemittanceRecipient);
    }
  }, [fields, handleAddressUpdate]);

  return (
    <Paper elevation={1} sx={{ p: 1, maxWidth: '800px', margin: 'auto' }}>
      <List>
        <CollapsibleSection
          title={t("General Details")}
          isOpen={isGeneralOpen}
          onToggle={toggleGeneral}
        >
          {items.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderBottom: '1px solid #e0e0e0'
              }}
            >
              <Typography variant="body2" color="textSecondary" marginBottom="5px">
                {t(item.key)}
              </Typography>
              {editableIndex === index ? (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    sx={{ flexGrow: 1 }}
                  />
                  <Button onClick={handleSave} sx={{ marginLeft: 2 }}>
                    {t("Save")}
                  </Button>
                  <DeleteIcon
                    sx={{ cursor: 'pointer', color: '#d32f2f', marginLeft: 2 }}
                    onClick={() => handleDelete(index)}
                  />
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ cursor: 'pointer', width: '100%' }}
                  onClick={() => handleEditClick(index)}
                >
                  {item.value}
                </Typography>
              )}
            </ListItem>
          ))}
        </CollapsibleSection>
        
        {/* Invoice Items Section */}
        {nestedItems && (
          <NestedItems 
            item={nestedItems} 
            onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'invoice_items')}
            changeMade={changeMade}
            isItemsOpen={isItemsOpen}
            toggleItems={toggleItems}
          />
        )}

        {/* Custom Fields Section */}
        {customFields && (
          <CustomFields 
            customFields={customFields} 
            onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'custom_fields')}
            changeMade={changeMade}
            isCustomFieldsOpen={isCustomFieldsOpen}
            toggleCustomFields={toggleCustomFields}
          />
        )}

        {/* Vendor Address Section */}
        {vendorAddress && (
          <Box>
            <NestedAddress 
              address={vendorAddress} 
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'vendor')} 
              changeMade={changeMade}
              title="Vendor Address" 
              isAddressOpen={isVendorAddressOpen}
              toggleAddress={toggleVendorAddress}
            />
            {vendorRecipient && (
              <Typography variant="subtitle1">
                {t("Recipient:")} {vendorRecipient}
              </Typography>
            )}
          </Box>
        )}

        {/* Customer Address Section */}
        {customerAddress && (
          <Box>
            <NestedAddress 
              address={customerAddress}
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'customer')} 
              changeMade={changeMade}
              title="Customer Address" 
              isAddressOpen={isCustomerAddressOpen}
              toggleAddress={toggleCustomerAddress} 
            />
            {customerRecipient && (
              <Typography variant="subtitle1">
                {t("Recipient:")} {customerRecipient}
              </Typography>
            )}
          </Box>
        )}

        {/* Billing Address Section */}
        {billingAddress && (
          <Box>
            <NestedAddress 
              address={billingAddress} 
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'billing')} 
              changeMade={changeMade} 
              title="Billing Address" 
              isAddressOpen={isBillingAddressOpen}
              toggleAddress={toggleBillingAddress}
            />
            {billingRecipient && (
              <Typography variant="subtitle1">
                {t("Recipient:")} {billingRecipient}
              </Typography>
            )}
          </Box>
        )}

        {/* Shipping Address Section */}
        {shippingAddress && (
          <Box>
            <NestedAddress 
              address={shippingAddress} 
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'shipping')} 
              changeMade={changeMade}
              title="Shipping Address" 
              isAddressOpen={isShippingAddressOpen}
              toggleAddress={toggleShippingAddress} 
            />
            {shippingRecipient && (
              <Typography variant="subtitle1">
                {t("Recipient:")} {shippingRecipient}
              </Typography>
            )}
          </Box>
        )}
      
        {/* Service Address Section */}
        {serviceAddress && (
          <Box>
            <NestedAddress 
              address={serviceAddress}
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'service')}  
              changeMade={changeMade}
              title="Service Address" 
              isAddressOpen={isServiceAddressOpen}
              toggleAddress={toggleServiceAddress} 
            />
            {serviceRecipient && (
                <Typography variant="subtitle1">
                {t("Recipient:")} {serviceRecipient}
              </Typography>
            )}
          </Box>
        )}

        {/* Remittance Address Section */}
        {remittanceAddress && (
          <Box>
            <NestedAddress 
              address={remittanceAddress} 
              onItemsRetrieved={(retrievedItems) => handleItemsRetrieved(retrievedItems, 'remittance')}  
              changeMade={changeMade}
              title="Remittance Address" 
              isAddressOpen={isRemittanceAddressOpen}
              toggleAddress={toggleRemittanceAddress}
            />
            {remittanceRecipient && (
                <Typography variant="subtitle1">
                  {t("Recipient:")} {remittanceRecipient}
                </Typography>
            )}
          </Box>
        )}
      </List>
    </Paper>
  );
};










