import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { 
  Button, 
  TextField, 
  InputAdornment, 
  Box, 
  Typography, 
  Dialog, 
  DialogContent, 
  DialogActions,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAxios from "../../utils/useAxios";

export const SignupForm = () => {
  const demo = !localStorage.getItem("authTokens");
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { t } = useTranslation();
  const axios = useAxios();
  const theme = useTheme();

  const url = React.useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const addEmail = async () => {
    const status = { "email": email };
    try {
      if (demo) {
        const response = await fetch(`${url}/api/email/subscribe/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(status)
        });
    
        if (!response.ok) {
          throw new Error('Failed to add email:', response.statusText);
        }
      } else {
        await axios.post(`${url}/api/email/subscribe/`, 
          status,
          {
          headers: {
            'Content-Type': 'application/json'
          },
        });
      }
      
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error('Add email error:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEmail();
    setEmail('');
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <Box 
      sx={{ 
        textAlign: 'center', 
        mt: 10,
        py: 8,
        px: 4,
        backgroundColor: '#e0f2fe',
        borderRadius: '20px',
      }}
    > 
      <Typography variant="h4" gutterBottom fontWeight={500} sx={{ color: 'black' }}>
        {t("It's getting better everyday")}
      </Typography>
      <Typography
        variant="h6"
        sx={{ color: 'text.secondary', maxWidth: '600px', mx: 'auto', mb: 4 }}
      >
        {t("Get in touch with us to gain information about future updates.")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box 
          display="flex" 
          flexDirection={{ xs: 'column', sm: 'row' }} 
          gap={2} 
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            type="email"
            id="email"
            placeholder={t("Enter your email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: '400px',
              textTransform: 'none',
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
                backgroundColor: 'white',
              },
            }}
          />

          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{
              borderRadius: '50px',
              px: 4,
              py: 1,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              backgroundColor: '#1c4ed8',
              boxShadow: `0px 4px 15px #1c4ed840`,
              '&:hover': {
                backgroundColor: '#1c4ed8',
                boxShadow: `0px 6px 20px ${theme.palette.primary.main}60`,
              }
            }}
          >
            {t("Sign Up For Updates")}
          </Button>
        </Box>
      </form>

      <Dialog
        open={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '20px',
            textAlign: 'center',
          }
        }}
      >
        <DialogContent>
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center"
            gap={2}
          >
            <FontAwesomeIcon 
              icon={faCheckCircle} 
              style={{ 
                color: '#1c4ed8', 
                fontSize: '4rem',
                marginBottom: '10px'
              }} 
            />
            <Typography variant="h5" fontWeight={600}>
              {t("Subscription Successful!")}
            </Typography>
            <Typography variant="body1">
              {t("You've successfully subscribed to our updates.")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Button 
            onClick={handleCloseSuccessModal} 
            variant="contained"
            sx={{
              borderRadius: '50px',
              px: 4,
              py: 1.5,
              color: 'white', 
              fontSize: '1rem',
              fontWeight: 600,
              backgroundColor: '#1c4ed8',
              boxShadow: `0px 4px 15px ${theme.palette.primary.main}40`,
              '&:hover': {
                backgroundColor: '#1c4ed8',
                boxShadow: `0px 6px 20px ${theme.palette.primary.main}60`,
              }
            }}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

