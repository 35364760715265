import React, { useCallback } from 'react';
import { Download, Edit } from 'lucide-react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const InvoiceActions = ({ invoice, onSave, axios }) => {
  const navigate = useNavigate();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  const demo = !localStorage.getItem("authTokens");

  const updateStatus = useCallback(async (invoiceId) => {
    if (demo) {
      try {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ status: "IN_REVIEW" })
        });
        
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    } else {
      try {
        await axios.post(`${url}/api/invoices/${invoiceId}/`, 
          { status: "IN_REVIEW" },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      } catch (error) {
        console.error('Update error:', error);
        throw error;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  const handleGoInvoice = useCallback(async(invoices) => {
    try {
      const invoiceIds = invoices.map((invoice) => invoice.id).join(',');
      const filePaths = invoices.map((invoice) => `${url}${invoice.file}`).join(',');
      const addedByList = invoices.map((invoice) => invoice.addedBy || invoice.uploaded_by);
      const addedOnList = invoices.map((invoice) => invoice.addedOn || invoice.uploaded_at);
      const status = invoices.map((invoice) => invoice.status)[0];
      const invoiceId = invoiceIds.split(",")[0];

      // If any invoice has "PENDING_REVIEW" status, update it
      if (status === "PENDING_REVIEW") {
        updateStatus(invoiceId);
      }
      
      // Navigate after ensuring status is updated
      navigate(`/invoice/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}&addedByList=${encodeURIComponent(addedByList)}&addedOnList=${encodeURIComponent(addedOnList)}`);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  }, [navigate, updateStatus, url]);

  const handleEdit = async () => {
    // Go to the invoice details page
    handleGoInvoice([invoice.data]);
    onSave?.();
  };

  const handleExport = async () => {
    try {
      const pdfUrl = `${url}${invoice.data.file}`;
      // Fetch the PDF file as a blob
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      
      // Create object URL from blob
      const objectUrl = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = `invoice-${invoice.data.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error('Export error:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'center' }}>
      <Button
        variant="contained"
        startIcon={<Edit />}
        onClick={handleEdit}
        sx={{
          mt: 2,
          py: 1,
          px: 4,
          borderRadius: '25px',
          textTransform: 'none',
          bgcolor: '#1c4ed8',
          color: 'white',
        }}
      >
        Edit Invoice
      </Button>
      <Button
        variant="contained"
        color="success"
        startIcon={<Download />}
        onClick={handleExport}
        sx={{
          mt: 2,
          py: 1,
          px: 4,
          borderRadius: '25px',
          textTransform: 'none'
        }}
      >
        Export PDF
      </Button>
    </Box>
  );
};