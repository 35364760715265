import * as React from 'react';
import { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
const Card = styled(MuiCard)(({ theme }) => ({
    borderColor: 'transparent',
    display: 'flex',
    backgroundColor:'transparent',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  }));
  
export default function ResetPasswordCard() {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const uid = queryParams.get('uid');
  const token = queryParams.get('token');
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const validateInputs = () => {
    let isValid = true;

    if (newPassword !== confirmPassword) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorMessage(t('Passwords do not match'));
        isValid = false;
      }
    else {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorMessage('');
    }
  
    if (newPassword.length < 8) {

        setNewPasswordError(true);
        setNewPasswordErrorMessage(t('Password must be at least 8 characters long'));
        isValid = false;
    }
    else {
        setNewPasswordError(false);
        setNewPasswordErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate inputs
    if (!validateInputs()) {
        return;
      }
    setMessage('');
    setLoading(true);

    try {
      const response = await fetch(`${url}/api/reset-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: uid,
          token: token,
          new_password: newPassword
        })
      });

      if (response.status === 200) {
        setSuccess(true);

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
      else {
        setMessage(t('Invalid or expired link'));
        setNewPasswordError('');
        setConfirmPasswordError('');
      }
    } catch (err) {
      setMessage(t('Invalid or expired link'));
      setNewPasswordError('');
      setConfirmPasswordError('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card variant="outlined">
      <Typography
        component="h1"
        fontStyle="bold"
        variant="h6"
        sx={{ width: '100%', fontSize: 'clamp(3rem, 20vw, 2.15rem)' }}
      >
        {t("Reset Password")}
      </Typography>
      {message && (
        <Typography color="error" sx={{ textAlign: "center" }}>
          {message}
        </Typography>
      )}
      {success && (
        <Typography color="success.main" sx={{ textAlign: "center" }}>
          {t('Password reset successful. You will be redirected to the login page.')}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl fullWidth error={newPasswordError}>
          <FormLabel htmlFor="newPassword"/>
          <TextField
            margin="normal"
            autoFocus
            required
            fullWidth
            name="newPassword"
            placeholder={t("New Password")}
            type="password"
            helperText={newPasswordError ? newPasswordErrorMessage : ''}
            error={newPasswordError}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{
                '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                border: 1,
                borderColor: 'black',
                },
            }}
        />
        </FormControl>
        <FormControl fullWidth error={confirmPasswordError}>
          <FormLabel htmlFor="confirmPassword"/>
          <TextField
            margin="normal"
            autoFocus
            required
            fullWidth
            name="confirmPassword"
            placeholder={t("Confirm Password")}
            type="password"
            helperText={confirmPasswordError ? confirmPasswordErrorMessage : ''}
            error={confirmPasswordError}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
                '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                border: 1,
                borderColor: 'black',
                },
            }}
        />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 3, 
            mb: 2,
            borderRadius: '50px',
            backgroundColor: '#1c4ed8'
          }}
          disabled={success || loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : t("Reset password")}
        </Button>
      </Box>
    </Card>
  );
} 