import React from 'react';
import { X } from 'lucide-react';
import { Box, IconButton } from '@mui/material';

export const ImagePreview = ({ image, onRemove }) => {
  if (!image) return null;

  return (
    <Box sx={{ 
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 1
    }}>
      <Box sx={{ position: 'relative', 'button': { opacity: 1 } }}>
        <img
          src={image.preview}
          alt="Receipt"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '540px',
            objectFit: 'contain',
            borderRadius: '8px'
          }}
        />
        <IconButton
          onClick={onRemove}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            bgcolor: 'error.main',
            color: 'white',
            opacity: 0,
            transition: 'opacity 0.2s',
            '&:hover': {
              bgcolor: 'error.dark'
            }
          }}
          size="small"
        >
          <X size={24} />
        </IconButton>
      </Box>
    </Box>
  );
};