import moment from "moment";

// Function to generate PDF from extracted receipt data
export const generateInvoicePDF = async (extractedText, demo, axios, skipParsing = false, items = null) => {
  try {
    let parsedReceiptData, parsedReceiptItems;
    if (!skipParsing) {

      // Parse receipt data and items in parallel
      [parsedReceiptData, parsedReceiptItems] = await Promise.all([
        parseReceiptData({ 
          axios, 
          demo,
          LLM: 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo', 
          extractedText 
        }),
        parseReceiptItems({ 
          axios, 
          demo,
          LLM: 'meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo', 
          extractedText
        })
      ]);
      
      console.log('Parsed receipt data:', parsedReceiptData);
      console.log('Parsed receipt items:', parsedReceiptItems);

      if (!parsedReceiptData) {
        throw new Error('Failed to parse receipt data');
      }

      if (!parsedReceiptItems || !parsedReceiptItems.items || !Array.isArray(parsedReceiptItems.items)) {
        throw new Error('Failed to parse receipt items or invalid items structure');
      }

      // Check for required fields
      const required = ['invoiceIdNumber', 'invoiceDate', 'dueDate', 'customerName', 'customerAddress'];
      const missingFields = required.filter(field => {
        // if (field === 'customerAddress') {
        //   const address = parsedReceiptData[field];
        //   // Return true (missing) if address is missing or all fields are empty
        //   return !address || 
        //          (!address.streetAddress && 
        //           !address.city &&
        //           !address.state && 
        //           !address.postalCode);
        // }
        return !parsedReceiptData[field];
      });
      
      if (missingFields.length > 0) {
        return { missingFields, parsedReceiptData, parsedReceiptItems };
      }
    } else {
      parsedReceiptData = extractedText;
      parsedReceiptItems = items;
    }

    // Format the data similar to AddFrame structure
    const formattedData = {
      ...parsedReceiptData,
      invoiceDate: parsedReceiptData.invoiceDate ? moment(parsedReceiptData.invoiceDate).format('DD-MM-YYYY') : null,
      dueDate: parsedReceiptData.dueDate ? moment(parsedReceiptData.dueDate).format('DD-MM-YYYY') : null,
      items: parsedReceiptItems.items.map(item => {
        if (!item) {
          console.warn('Encountered null or undefined item');
          return null;
        }
        return {
          ...item,
          itemDate: item.itemDate ? moment(item.itemDate).format('DD-MM-YYYY') : null,
          unitPrice: parseFloat(item.unitPrice) || 0,
          amount: parseFloat(item.amount) || 0,
          tax: parseFloat(item.tax) || 0,
          currencyCode: item.currency?.code || 'USD',
          currencySymbol: item.currency?.symbol || '$',
          currencyPosition: item.currency?.position || 'prefix'
        };
      }).filter(item => item !== null) // Remove any null items
    };

    console.log(formattedData);

    // Create FormData instance
    const formDataToSend = new FormData();
    formDataToSend.append('invoice_data', JSON.stringify(formattedData));
    formDataToSend.append('company', localStorage.getItem('cachedBusinessName') || '');
    formDataToSend.append('category', formattedData.category || '');
    console.log(formDataToSend);

    // Send to backend
    const internalUrl = process.env.REACT_APP_API_BASE_URL;
    const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';

    let response;
    if (demo) {
      response = await fetch(`${url}/api/invoices/create/`, {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'Accept': 'application/json',
          // Don't set Content-Type when sending FormData
          // 'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies if needed
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }
      return data;
    } else {
      // The axios instance already handles CORS headers
      response = await axios.post(`${url}/api/invoices/create/`, formDataToSend);
      if (response.status !== 200) {
        throw new Error('Failed to generate PDF');
      }
      return response.data;
    }

  } catch (error) {
    console.error('Error in generateInvoicePDF:', error);
    throw error;
  }
};


// OCR function using Together AI
export async function ocr({
  filePath,
  model = "Llama-3.2-90B-Vision",
  axios,
}) {
  let visionLLM = `meta-llama/${model}-Instruct-Turbo`;

  try {
    const markdown = await getMarkDown({ visionLLM, filePath, axios });
    return markdown;
  } catch (error) {
    // If first attempt fails, try with Vision-Llama-Free model
    visionLLM = 'meta-llama/Llama-Vision-Free';
    const markdown = await getMarkDown({ visionLLM, filePath, axios });
    return markdown;
  }
}

function encodeImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

function isRemoteFile(filePath) {
  return typeof filePath === 'string' && 
    (filePath.startsWith("http://") || filePath.startsWith("https://"));
}

// Helper function to get markdown from OCR
async function getMarkDown({
  visionLLM,
  filePath,
  axios,
}) {
  const demo = !localStorage.getItem("authTokens");
  let finalImageUrl;
  if (isRemoteFile(filePath)) {
    finalImageUrl = filePath;
  } else {
    const base64 = await encodeImage(filePath);
    finalImageUrl = `data:image/jpeg;base64,${base64}`;
  }

  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  
  const formData = new FormData();
  formData.append('file_url', finalImageUrl);
  formData.append('model', visionLLM);
  
  let response;
  if (demo) {
    response = await fetch(`${url}/api/receipts/ocr/`, {
      method: 'POST',
      body: formData
    });
    const data = await response.json();
    return data.data;
  } else {
    response = await axios.post(`${url}/api/receipts/ocr/`, formData);
    return response.data;
  }
}

// Add this helper function at the top with other utility functions
function cleanAndValidateJSON(jsonString) {
  try {
    // If it's already a valid JSON object/array, return it
    if (typeof jsonString === 'object') {
      return jsonString;
    }

    // Remove any markdown code block syntax
    let cleaned = jsonString.replace(/```json\s*|\s*```/g, '');
    
    // Determine if we're dealing with an array or object
    const isArray = cleaned.trim().startsWith('[');
    
    if (isArray) {
      // Find the first [ and last ]
      const startIndex = cleaned.indexOf('[');
      const endIndex = cleaned.lastIndexOf(']');
      
      if (startIndex === -1 || endIndex === -1) {
        throw new Error('No valid JSON array found in the string');
      }
      
      cleaned = cleaned.substring(startIndex, endIndex + 1);
    } else {
      // Find the first { and last }
      const startIndex = cleaned.indexOf('{');
      const endIndex = cleaned.lastIndexOf('}');
      
      if (startIndex === -1 || endIndex === -1) {
        throw new Error('No valid JSON object found in the string');
      }
      
      cleaned = cleaned.substring(startIndex, endIndex + 1);
    }
    
    // Fix common JSON formatting issues
    cleaned = cleaned
      .replace(/,(\s*[}\]])/g, '$1') // Remove trailing commas
      .replace(/[\n\r]/g, ' ') // Remove newlines
      .replace(/\s+/g, ' ') // Normalize whitespace
      .replace(/\\"/g, '"') // Fix escaped quotes
      .replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3') // Quote unquoted keys
      .trim();

    // Attempt to parse the cleaned JSON
    return JSON.parse(cleaned);
  } catch (error) {
    console.error('JSON cleaning failed:', error);
    console.error('Original string:', jsonString);
    throw new Error(`Invalid JSON structure: ${error.message}`);
  }
}

// Helper function to parse the receipt data from the markdown text
async function parseReceiptData({
  axios,
  demo,
  LLM,
  extractedText,
}) {
  const systemPrompt = `You are a receipt data extraction expert. Analyze the provided receipt text and extract relevant information into a structured format. Follow these guidelines:

1. Extract key receipt information including:
   - receiptId
   - vendorName
   - vendorId
   - customerName
   - customerId
   - invoiceDate (YYYY-MM-DD)
   - dueDate (YYYY-MM-DD)
   - subtotal (numerical value without currency)
   - taxAmount (numerical value without currency)
   - invoiceTotal (numerical value without currency)
   - currency (object with: symbol, code, position (before or after))
   - purchaseOrder
   - previousUnpaidBalance (numerical value without currency)
   - amountDue (numerical value without currency) 
   - category (Advertising & Marketing, Agriculture & Food Production, Arts & Culture, Automotive, Craftsmanship, Design, Education, Energy & Utilities, Entertainment & Media, Finance, Food & Beverage, Healthcare, Home & Lifestyle, Logistics & Transportation, Luxury Retail, Manufacturing & Industry, Non-Profit & Social Impact, Personal Services, Real Estate, Retail, Science & Development, Security, Sport & Recreation, Sustainability & Environment, Technology, Telecommunications, Tourism, Uncategorized)

2. For addresses, use camelCase format:
   - houseNumber
   - streetName
   - city
   - state
   - postalCode
   - streetAddress (full address)

3. Handle multiple address types as objects:
   - customerAddress
   - billingAddress
   - vendorAddress
   - shippingAddress
   - serviceAddress
   - remittanceAddress

4. Additional guidelines:
   - Return empty strings for fields not found in the receipt
   - Maintain consistent formatting for dates (YYYY-MM-DD)
   - Extract numerical values without currency symbols
   - Preserve exact spelling and formatting of names


Format the response as a JSON object matching the provided schema. Ensure all fields are strings, even for numerical values.`;

  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';

  const formData = new FormData();
  formData.append('receipt_text', extractedText);
  formData.append('model', LLM);
  formData.append('system_prompt', systemPrompt);

  let response;
  if (demo) {
    response = await fetch(`${url}/api/receipts/json/`, {
      method: 'POST',
      body: formData
    });
    response = await response.json();
  } else {
    response = await axios.post(`${url}/api/receipts/json/`, formData);
  }

  try {
    if (response) {
      const parsedOutput = cleanAndValidateJSON(response.data);
      console.log('Parsed output:', parsedOutput);
      return parsedOutput;
    }
    return null;
  } catch (error) {
    console.error('Error in parseReceiptData:', error);
    throw error;
  }
}

async function parseReceiptItems({
  axios,
  demo,
  LLM,
  extractedText,
}) {
  const systemPrompt = `You are a receipt line item extraction expert. Analyze the provided receipt text and extract individual item details into a structured format. Follow these guidelines:

1. For each line item, extract:
   - itemDescription (full product/service name)
   - itemQuantity (numerical value)
   - unit (e.g., "each", "kg", "hours", "pieces")
   - unitPrice (numerical value without currency)
   - productCode (if present)
   - itemDate (for dated services/products, format: YYYY-MM-DD)
   - tax (numerical value without currency)
   - amount ( total amount for the item, numerical value without currency)
   - currencySymbol
   - currencyPosition (before or after)

2. Formatting guidelines:
   - Remove currency symbols from numerical values
   - Preserve exact product names and descriptions
   - Convert units to standardized format
   - Return empty strings for unavailable fields
   - Format dates as YYYY-MM-DD
   - Round numerical values to 2 decimal places

3. Special considerations:
   - Handle multi-line item descriptions
   - Account for bulk pricing and discounts
   - Identify service items vs physical products
   - Parse package deals or bundled items
   - Handle tax-inclusive vs tax-exclusive prices

Format each item as a JSON object matching the provided schema. Return an array of all identified items.`;

  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';

  const formData = new FormData();
  formData.append('receipt_text', extractedText);
  formData.append('model', LLM);
  formData.append('system_prompt', systemPrompt);

  let response;
  if (demo) {
    response = await fetch(`${url}/api/receipts/json/`, {
      method: 'POST',
      body: formData
    });
    response = await response.json();
  } else {
    response = await axios.post(`${url}/api/receipts/json/`, formData);
  }

  try {
    if (response) {
      const parsedOutput = cleanAndValidateJSON(response.data);
      console.log('Parsed output:', parsedOutput);
      // Wrap the array in an object with 'items' property if it's an array
      if (Array.isArray(parsedOutput)) {
        return { items: parsedOutput };
      }
      return parsedOutput;
    }
    return null;
  } catch (error) {
    console.error('Error in parseReceiptItems:', error);
    throw error;
  }
}