import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { LineChart } from '@mui/x-charts/LineChart';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const InvoiceTimelineChart = ({ data }) => {
  const theme = useTheme();

  // Data is already in the correct format from the API
  const chartData = data.map(item => ({
    date: item.week,
    count: item.count
  }));

  const totalInvoices = chartData.reduce((sum, { count }) => sum + count, 0);
  const previousPeriodTotal = 15; // Placeholder for comparison
  const percentageChange = ((totalInvoices - previousPeriodTotal) / previousPeriodTotal) * 100;

  const color = theme.palette.primary.main;

  return (
    <Card variant="outlined" sx={{ width: '830px', borderRadius: '20px' }}>
      <CardContent>
        <Typography component="h4" variant="subtitle2 bold" gutterBottom>
          Weekly Invoice Timeline
        </Typography>
        {/* Summary Section */}
        <Stack sx={{ justifyContent: 'space-between', marginBottom: 2 }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {totalInvoices.toLocaleString()}
            </Typography>
            <Chip
              size="small"
              color={percentageChange >= 0 ? 'success' : 'error'}
              label={`${percentageChange >= 0 ? '+' : ''}${percentageChange.toFixed(1)}% (Last Month)`}
            />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Total invoices submitted compared to the last period
          </Typography>
        </Stack>
        {/* Chart */}
        <LineChart
          colors={[color]}
          xAxis={[
            {
              scaleType: 'point',
              data: chartData.map((d) => d.date),
              tickInterval: (index) => index % 2 === 0, // Show every other tick
            },
          ]}
          series={[
            {
              id: 'invoices',
              label: 'Invoices',
              showMark: false,
              curve: 'linear',
              area: true,
              data: chartData.map((d) => d.count),
            },
          ]}
          height={300}
          margin={{ left: 50, right: 20, top: 20, bottom: 0 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-invoices': {
              fill: "url('#invoice-gradient')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={color} id="invoice-gradient" />
        </LineChart>
      </CardContent>
    </Card>
  );
};

InvoiceTimelineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      week: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default InvoiceTimelineChart;


