import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { Typography, Button } from "@mui/material";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';
import "./style.css";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Chatbot = ({ invoiceId }) => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const demo = !localStorage.getItem("authTokens");
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const axios = useAxios();

  const [sessionId, setSessionId] = useState(undefined);

  const [suggestions] = useState([
    "When is the due date?",
    "Summarise the invoice",
    "Compare with the previous invoices",
    "Ask a legal or an accounting-related question"
  ]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [awaitingCategorySelection, setAwaitingCategorySelection] = useState(false);
  const categories = ["Legal", "Accounting"];

  const url = useMemo(
    () => (internalUrl ? `https://${internalUrl}` : "http://127.0.0.1:8000"),
    [internalUrl]
  );

  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const TypingIndicator = () => (
    <div className="typing-indicator">
      <div className="typing-dot"></div>
      <div className="typing-dot"></div>
      <div className="typing-dot"></div>
    </div>
  );

  const handleSuggestionClick = (suggestion) => {
    if (suggestion === "Ask a legal or an accounting-related question") {
      setAwaitingCategorySelection(true);
      const followUpMessage = { text: "Please choose a category:", sender: "bot" };
      setMessages((prev) => [...prev, followUpMessage]);
      setShowSuggestions(false);
    } else {
      handleSendMessage(suggestion);
      setShowSuggestions(false);
    }
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setAwaitingCategorySelection(false);
    const greetingMessage = {
      text: `Hello! I'm your ${category.toLowerCase()} assistant. How can I help you today?`,
      sender: "bot",
    };
    // remove the previous messages
    setMessages([]);
    setMessages((prev) => [...prev, greetingMessage]);
  };

  const handleSendMessage = async (directMessage = null, category = null) => {
    const messageToSend = directMessage || input.trim();
    if (!messageToSend || isLoading) return;

    const userMessage = { text: messageToSend, sender: "user" };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);
    setIsTyping(true);
    setError(null);

    try {
      const conversationHistory = messages.slice(-4);
      const conversationHistoryFiltered = conversationHistory.filter(message => 
        !message.text.includes("Hello! I'm your accounting assistant") &&
        !message.text.includes("Hello! I'm your legal assistant")
      );

      let botMessage = { text: "Sorry, I didn't understand that.", sender: "bot" };

      if (demo) {
        const response = await fetch(`${url}/api/chat/${invoiceId}/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            message: userMessage.text,
            history: conversationHistoryFiltered,
            session_id: sessionId,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          botMessage.text = data.response || botMessage.text;
          if (data.session_id) {
            setSessionId(data.session_id);
          }
        } else {
          console.error("Fetch failed with status:", response.status);
        }
      } else {
        if (selectedCategory === 'Legal') {
          const data = await sendLegalQuestion(invoiceId, userMessage.text, conversationHistoryFiltered, sessionId);
          botMessage.text = data.response || botMessage.text;
          if (data.session_id) {
            setSessionId(data.session_id);
          }
        } else if (selectedCategory === 'Accounting') {
          const data = await sendAccountingQuestion(invoiceId, userMessage.text, conversationHistoryFiltered, sessionId);
          botMessage.text = data.response || botMessage.text;
          if (data.session_id) {
            setSessionId(data.session_id);
          }
        } else {
          const response = await axios.post(`${url}/api/chat/${invoiceId}/`,
            {
              message: userMessage.text,
              history: conversationHistoryFiltered,
              session_id: sessionId,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          botMessage.text = response.data.response || botMessage.text;
          if (response.data.session_id) {
            setSessionId(response.data.session_id);
          }
        }
      }

      setMessages((prev) => [...prev, botMessage]);

      setShowSuggestions(true);
    } catch (error) {
      console.error("Error sending message:", error);
      setError(t("An unexpected error occurred. Please try again."));
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const sendLegalQuestion = async (invoiceId, message, history, sessionId) => {
    if (message.length <= 1) {
      setMessages([]);
    }
    const url = `https://${internalUrl}/api/chat/legal/${invoiceId}/`;
    const requestBody = {
      message,
      history,
      session_id: sessionId,
    };
    try {
      const response = await axios.post(url, requestBody, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    } catch (error) {
      console.error("Legal API Error:", error);
      throw error;
    }
  };

  const sendAccountingQuestion = async (invoiceId, message, history, sessionId) => {
    const url = `https://${internalUrl}/api/chat/accounting/${invoiceId}/`;
    const requestBody = {
      message,
      history,
      session_id: sessionId,
    };
    try {
      const response = await axios.post(url, requestBody, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    } catch (error) {
      console.error("Accounting API Error:", error);
      throw error;
    }
  };

  const clearMessages = () => {
    setMessages([]);
    setAwaitingCategorySelection(false);
    setError(null);
    setShowSuggestions(true);
    setSelectedCategory(null);
    setSessionId(undefined);
  }

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <Typography color="white" variant="h6">
          {t("Your Personal Assistant")}
        </Typography>
        <button onClick={clearMessages} className="clear-chat-btn" title="Clear Chat">
          <FontAwesomeIcon icon={faBroom} size="lg" /> {t("Clear Chat")}
        </button>
      </div>

      <div ref={messagesContainerRef} className="chatbot-messages">
        {messages.length === 0 && (
          <div className="welcome-message">
            <Typography variant="body1" className="message bot">
              {t("Hello! I'm your invoice assistant. How can I help you today?")}
            </Typography>
          </div>
        )}
        
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
          </div>
        ))}
        
        {awaitingCategorySelection && (
          <Stack direction="row" spacing={2} className="category-buttons">
            {categories.map((category) => (
              <Button
                key={category}
                variant="contained"
                onClick={() => handleCategorySelection(category)}
                sx={{
                  backgroundColor: '#1c4ed8',
                  color: 'white',
                  borderRadius: '25px',
                  textTransform: 'none',
                  padding: '8px 24px',
                }}
              >
                {category}
              </Button>
            ))}
          </Stack>
        )}
        
        {showSuggestions && messages.length <= 1 && (
          <Stack direction="row" spacing={1} className="suggestions-container">
            {suggestions.map((suggestion, index) => (
              <Chip
                key={index}
                label={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-chip"
                variant="outlined"
              />
            ))}
          </Stack>
        )}
        
        {isTyping && <TypingIndicator />}
        {error && <div className="message system">{error}</div>}
        <div ref={messagesEndRef} />
      </div>

      <div className="chatbot-input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={t("Type your message...")}
          className="chatbot-input"
          disabled={isLoading}
        />
        <button onClick={() => handleSendMessage()} className="chatbot-send-btn" disabled={isLoading}>
          {isLoading ? t("Sending...") : t("Send")}
        </button>
      </div>
    </div>
  );
};

export default Chatbot;


