import React, { useState, useCallback } from "react";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import upload from "./upload.svg"; // Import upload SVG
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import { useTranslation } from 'react-i18next';
import "./style.css";

export const UploadTemplateFrame = ({ onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUpload = async () => {
    if (files.length === 0) return;

    try {
      setLoading(true);
      // Create a URL for the first uploaded image
      const logoUrl = URL.createObjectURL(files[0]);
      onClose(logoUrl, files[0]);
    } catch (error) {
      setError("Upload failed. Please try again.");
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
    setError(""); // Reset error on file selection
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.match(/image\/(png|jpeg|jpg)/)
    );
    setFiles(droppedFiles);
    setError("");
  }, []);

  const handleClose = () => {
    onClose([]); // Return empty array if closed without uploading
  };

  return (
    <>
      {/* Backdrop */}
      <div className="upload-backdrop"></div>

      {/* Loading Screen */}
      {loading && (
        <div className="loading-overlay">
          <LoadingScreen numFiles={files.length} />
        </div>
      )}

      {/* Main Frame */}
      <div className="upload-frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              border: 1,
              borderColor: "#1c4ed8",
              color: "#1c4ed8",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Header */}
          <div className="upload-header">
            <h2 className="upload-title">{t("Upload Company Logo")}</h2>
            <p className="upload-subtitle">{t("Click to upload or drag & drop files below")}</p>
            <p className="upload-description">{t("Supported formats: PNG, JPG, JPEG")}</p>
          </div>

          {/* Dropzone */}
          <label
            htmlFor="dropzone-file"
            className="upload-label"
            onDrop={handleDrop}
            style={{
              height: "220px",
              width: "300px",
              border: "2px dashed #1c4ed8",
              overflowY: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <img src={upload} alt="Upload icon" className="upload-icon" />
            <input
              id="dropzone-file"
              type="file"
              accept=".png, .jpg, .jpeg"
              className="hidden"
              onChange={handleFileChange}
              multiple
            />
            {files.length > 0 && (
              <div className="file-preview">
                {files.map((file, index) => (
                  <div key={index} className="file-row">
                    <span className="truncate">{file.name}</span>
                    <IconButton
                      onClick={() => handleDeleteFile(index)}
                      size="small"
                      sx={{ color: "#ff1744", marginLeft: "10px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                <p className="file-count">{t("Total")}: {files.length} {t("file(s)")}</p>
              </div>
            )}
          </label>

          {/* Upload Button */}
          <div className="button-container">
            {error && <p className="error-message">{error}</p>}
            <Button
              sx={{
                color: "white",
                backgroundColor: "#1c4ed8",
                padding: "10px 20px",
                borderRadius: "50px",
                "&:hover": { backgroundColor: "#163bb7" },
              }}
              onClick={handleUpload}
              variant="contained"
              disabled={files.length === 0 || loading}
            >
              {t("Upload")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};


