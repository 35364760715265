import React, { useState, useCallback, memo, useMemo } from 'react';
import { 
    Button, 
    TextField, 
    Grid, 
    Box, 
    Typography, 
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    ListItem,
    ListItemText,
    List,
    CircularProgress,
    MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadTemplateFrame } from "../../components/UploadFrame/UploadTemplateFrame";
import {AddLink as AddIcon} from '@mui/icons-material';
import { Trash2, Edit2, Plus, ChevronDownIcon as ChevronDown } from 'lucide-react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react'; // Add this import at the top with other imports
import useAxios from "../../utils/useAxios";

// Add this constant at the top level of your file
const CURRENCIES = [
  { symbol: '$', code: 'USD', position: 'before' },
  { symbol: '£', code: 'GBP', position: 'before' },
  { symbol: 'zł', code: 'PLN', position: 'after' }
];

// Add this constant at the top level of your file after CURRENCIES
const categories = [
  "Advertising & Marketing",
  "Agriculture & Food Production",
  "Arts & Culture",
  "Automotive",
  "Craftsmanship", 
  "Design",
  "Education",
  "Energy & Utilities",
  "Entertainment & Media",
  "Finance",
  "Food & Beverage",
  "Healthcare",
  "Home & Lifestyle",
  "Logistics & Transportation",
  "Luxury Retail",
  "Manufacturing & Industry",
  "Non-Profit & Social Impact",
  "Personal Services",
  "Real Estate",
  "Retail",
  "Science & Development",
  "Security",
  "Sport & Recreation", 
  "Sustainability & Environment",
  "Technology",
  "Telecommunications",
  "Tourism",
  "Uncategorized"
];

// Memoized TextField component
const MemoizedTextField = memo(({ value, onChange, label, ...props }) => (
  <TextField
    value={value}
    onChange={onChange}
    label={label}
    {...props}
  />
));

// Memoized DatePicker component
const MemoizedDatePicker = memo(({ value, onChange, ...props }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DatePicker
      value={value}
      onChange={onChange}
      inputFormat="DD-MM-YYYY"
      renderInput={(params) => (
        <TextField 
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: "DD-MM-YYYY"
          }}
          fullWidth
        />
      )}
      {...props}
    />
  </LocalizationProvider>
));

// Memoized AddressFields component
const AddressFields = memo(({ prefix, title, address, onChange, t }) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      {t(title)}
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.houseNumber`}
          label={t("house_number")}
          variant="outlined"
          value={address.houseNumber}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.road`}
          label={t("road")}
          variant="outlined"
          value={address.road}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.city`}
          label={t("city")}
          variant="outlined"
          value={address.city}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.state`}
          label={t("state")}
          variant="outlined"
          value={address.state}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.postalCode`}
          label={t("postal_code")}
          variant="outlined"
          value={address.postalCode}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.streetAddress`}
          label={t("street_address")}
          variant="outlined"
          value={address.streetAddress}
          onChange={onChange}
          fullWidth
        />
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
  </>
));


// Memoized OptionalAddressFields component
const OptionalAddressFields = memo(({ prefix, title, address, onChange, t }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronDown />}
      aria-controls={`${prefix}-content`}
      id={`${prefix}-header`}
      sx={{ marginBottom: '5px' }}
    >
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        <Plus size={18} style={{ marginRight: '8px' }} />
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.houseNumber`}
            label={t("house_number")}
            variant="outlined"
            value={address.houseNumber}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.road`}
            label={t("road")}
            variant="outlined"
            value={address.road}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.city`}
            label={t("city")}
            variant="outlined"
            value={address.city}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.state`}
            label={t("state")}
            variant="outlined"
            value={address.state}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.postalCode`}
            label={t("postal_code")}
            variant="outlined"
            value={address.postalCode}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.streetAddress`}
            label={t("street_address")}
            variant="outlined"
            value={address.streetAddress}
            onChange={onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
));

// Memoized ItemsSection component
const ItemsSection = memo(({ 
    items, newItem, onItemChange, onItemDateChange, onAddItem, onDeleteItem, 
    onEditItem, onUpdateItem, editingItem, t, errors }) => (
  <>
    <div data-testid="items-section">
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {t("Line Items")}
      </Typography>
      
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Description")}</TableCell>
                <TableCell>{t("Product Code")}</TableCell>
                <TableCell align="right">{t("Quantity")}</TableCell>
                <TableCell align="right">{t("Unit Price")}</TableCell>
                <TableCell>{t("Date")}</TableCell>
                <TableCell align="right">{t("Tax")}</TableCell>
                <TableCell align="right">{t("Amount")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.itemDescription}</TableCell>
                  <TableCell>{item.productCode}</TableCell>
                  <TableCell align="right">{item.itemQuantity}</TableCell>
                  <TableCell align="right">
                    {item.currency.position === 'before' 
                      ? `${item.currency.symbol}${parseFloat(item.unitPrice).toFixed(2)}`
                      : `${parseFloat(item.unitPrice).toFixed(2)}${item.currency.symbol}`
                    }
                  </TableCell>
                  <TableCell>{moment(item.itemDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell align="right">
                    {item.currency.position === 'before' 
                      ? `${item.currency.symbol}${parseFloat(item.tax).toFixed(2)}`
                      : `${parseFloat(item.tax).toFixed(2)}${item.currency.symbol}`
                    }
                  </TableCell>
                  <TableCell align="right">
                    {item.currency.position === 'before' 
                      ? `${item.currency.symbol}${parseFloat(item.amount).toFixed(2)}`
                      : `${parseFloat(item.amount).toFixed(2)}${item.currency.symbol}`
                    }
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => onEditItem(item)} size="small">
                      <Edit2 size={16} />
                    </IconButton>
                    <IconButton onClick={() => onDeleteItem(item.id)} size="small" color="error">
                      <Trash2 size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          {t(editingItem ? 'Edit Item' : 'Add New Item')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <MemoizedTextField
              name="itemDescription"
              label={t("Description")}
              variant="outlined"
              value={newItem.itemDescription}
              onChange={onItemChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MemoizedTextField
              name="productCode"
              label={t("Product Code")}
              value={newItem.productCode}
              onChange={onItemChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MemoizedTextField
              name="itemQuantity"
              label={t("Quantity")}
              type="number"
              value={newItem.itemQuantity}
              onChange={onItemChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MemoizedTextField
              name="unitPrice"
              label={t("Unit Price")}
              type="number"
              value={newItem.unitPrice}
              onChange={onItemChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MemoizedDatePicker
              label={t("item_date")}
              value={newItem.itemDate}
              onChange={onItemDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <MemoizedTextField
              name="tax"
              label={t("Tax")}
              type="number"
              value={newItem.tax}
              onChange={onItemChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              select
              name="currency"
              label={t("Currency")}
              value={newItem.currency.code}
              onChange={(e) => {
                const selectedCurrency = CURRENCIES.find(curr => curr.code === e.target.value);
                onItemChange({
                  target: {
                    name: 'currency',
                    value: selectedCurrency
                  }
                });
              }}
              fullWidth
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {`${currency.symbol} ${currency.code}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={editingItem ? onUpdateItem : onAddItem}
              sx={{
                height: '100%',
                pl: 2,
                pr: 2,
                color: "white",
                backgroundColor: "#1c4ed8",
                borderRadius: "50px",
                "&:hover": { backgroundColor: "#163bb7" },
              }}
            >
              {t(editingItem ? 'Update' : 'Add')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {errors.items && (
        <Typography color="error" sx={{ mt: 1 }}>
          {errors.items}
        </Typography>
      )}
    </div>
  </>
));

// Add validation function at the top level
const validateForm = (formData) => {
  const errors = {};
  
  // Required field validation
  if (!formData.invoiceIdNumber?.trim()) {
    errors.invoiceIdNumber = 'Invoice ID is required';
  }
  if (!formData.vendorName?.trim()) {
    errors.vendorName = 'Vendor name is required';
  }
  if (!formData.customerName?.trim()) {
    errors.customerName = 'Customer name is required';
  }
  if (!formData.invoiceTotal?.trim()) {
    errors.invoiceTotal = 'Invoice total is required';
  }
  if (!formData.amountDue?.trim()) {
    errors.amountDue = 'Amount due is required';
  }
  if (!formData.items?.length) {
    errors.items = 'At least one line item is required';
  }
  
  return errors;
};

// Modify the scrollToError utility function
const scrollToError = (fieldName) => {
  let element;
  
  // Special handling for items error
  if (fieldName === 'items') {
    element = document.querySelector('[data-testid="items-section"]');
  } else {
    element = document.querySelector(`[name="${fieldName}"]`);
  }

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (fieldName !== 'items') {
      element.focus();
    }
  }
};

export const AddFrame = ({ onClose }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const demo = !localStorage.getItem("authTokens");
  const company = localStorage.getItem('cachedBusinessName', null);
  const axios = useAxios();
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [sameAsCustomer, setSameAsCustomer] = useState(false);
  const [newItem, setNewItem] = useState({
    itemDescription: '',
    itemQuantity: '',
    unit: '',
    unitPrice: '',
    productCode: '',
    itemDate: moment(),
    tax: '',
    amount: '',
    currency: CURRENCIES[0], // Default to first currency (USD)
  });

  const [formData, setFormData] = useState({
    invoiceIdNumber: '',
    customerName: '',
    customerId: '',
    invoiceDate: moment(),
    subtotal: '',
    vendorName: '',
    invoiceTotal: '',
    dueDate: null, // Changed from moment() to null
    hasDueDate: false, // Add this field to track checkbox state
    purchaseOrder: '',
    totalTax: '',
    previousUnpaidBalance: '',
    amountDue: '',
    items: [],
    customFields: [], 
    customFieldKey: '', 
    customFieldValue: '',
    addCustomFields: false,
    customerAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    billingAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    vendorAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    shippingAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    serviceAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    remittanceAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    category: '', // Add this line
  });

  const url = useMemo(() => {
      return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
    }, [internalUrl]);

  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTemplateClick = useCallback(() => setShowUploadFrame(true), []);

  const handleSubmit = async () => {
    const validationErrors = validateForm(formData);
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // Scroll to the first error field
      const firstError = Object.keys(validationErrors)[0];
      scrollToError(firstError);
      return;
    }
  
    const { addCustomFields, customFieldKey, customFieldValue, hasDueDate, ...invoiceData } = formData;
  
    // Format dates to only include the date part and clean up currency data
    const formattedData = {
      ...invoiceData,
      invoiceDate: invoiceData.invoiceDate ? moment(invoiceData.invoiceDate).format('DD-MM-YYYY') : null,
      dueDate: invoiceData.dueDate ? moment(invoiceData.dueDate).format('DD-MM-YYYY') : null,
      items: invoiceData.items.map(item => ({
        ...item,
        itemDate: item.itemDate ? moment(item.itemDate).format('DD-MM-YYYY') : null,
        // Keep numeric values clean without currency symbols
        unitPrice: parseFloat(item.unitPrice),
        amount: parseFloat(item.amount),
        tax: parseFloat(item.tax),
        // Send currency info separately
        currencyCode: item.currency.code,
        currencySymbol: item.currency.symbol,
        currencyPosition: item.currency.position
      }))
    };
  
    // Create FormData instance
    const formDataToSend = new FormData();
    formDataToSend.append('invoice_data', JSON.stringify(formattedData));
    formDataToSend.append('company', company || '');
    formDataToSend.append('category', formattedData.category || '');
  
    // Add the logo file if it exists
    if (logoFile) {
      formDataToSend.append('logo', logoFile);
    }
  
    setIsLoading(true);
  
    try {
      let response;
      if (demo) {
        response = await fetch(`${url}/api/invoices/create/`, {
          method: 'POST',
          body: formDataToSend,
        });
        // Parse the JSON response for fetch
        const data = await response.json();
        
        if (response.ok) {  // fetch uses response.ok instead of status
          onClose([data?.data]);
        } else {
          throw new Error('Failed to create invoice');
        }
      } else {
        // Axios request
        response = await axios.post(`${url}/api/invoices/create/`, formDataToSend);
        
        if (response.status === 201) {
          onClose([response.data?.data]);
        } else {
          throw new Error('Failed to create invoice');
        }
      }
    } catch (error) {
      console.error('Failed to create invoice:', error);
      // Handle the error gracefully
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleAddCustomField = () => {
    if (formData.customFieldKey && formData.customFieldValue) {
      setFormData(prev => ({
        ...prev,
        customFields: [
          ...prev.customFields,
          {
            key: prev.customFieldKey,
            value: prev.customFieldValue
          }
        ],
        customFieldKey: '', // Clear input fields
        customFieldValue: ''
      }));
    }
  };

  const handleRemoveCustomField = (index) => {
    setFormData(prev => ({
      ...prev,
      customFields: prev.customFields.filter((_, i) => i !== index)
    }));
  };

  const handleSameAsCustomerChange = useCallback((event) => {
    setSameAsCustomer(event.target.checked);
    if (event.target.checked) {
      setFormData(prev => ({
        ...prev,
        billingAddress: { ...prev.customerAddress }
      }));
    }
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');

    setErrors(prev => ({ ...prev, [name]: undefined }));

    setFormData((prevFormData) => {
      if (name === 'hasDueDate') {
        // When unchecking the due date checkbox, set dueDate to null
        return {
          ...prevFormData,
          hasDueDate: value,
          dueDate: value ? moment() : null
        };
      }

      if (nameParts.length === 2) {
        const [parent, child] = nameParts;
        return {
          ...prevFormData,
          [parent]: {
            ...prevFormData[parent],
            [child]: value,
          },
        };
      }
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }, []);

  // Make sure handleDateChange respects hasDueDate
  const handleDateChange = useCallback((name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: name === 'dueDate' && !prev.hasDueDate ? null : moment(value),
    }));
  }, []);


  const calculateAmount = useCallback((quantity, unitPrice) => {
    return parseFloat(quantity) * parseFloat(unitPrice);
  }, []);

  const handleItemChange = useCallback((e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'itemQuantity' || name === 'unitPrice' ? parseFloat(value) : value;
  
    setNewItem(prev => {
      const updated = { ...prev, [name]: parsedValue };
      if (name === 'itemQuantity' || name === 'unitPrice') {
        const itemQuantity = name === 'itemQuantity' ? parsedValue : prev.itemQuantity;
        const unitPrice = name === 'unitPrice' ? parsedValue : prev.unitPrice;
        updated.amount = calculateAmount(itemQuantity, unitPrice);
      }
      return updated;
    });
  }, [calculateAmount]);

  const handleItemDateChange = useCallback((value) => {
    setNewItem(prev => ({
      ...prev,
      itemDate: moment(value),
    }));
  }, []);

  const handleAddItem = () => {
    setErrors(prev => ({ ...prev, items: undefined }));

    const itemToAdd = {
      ...newItem,
      id: formData.items.length + 1,
    };

    setFormData(prev => ({
      ...prev,
      items: [...prev.items, itemToAdd],
    }));

    setNewItem({
      itemDescription: '',
      itemQuantity: 0,
      unit: '',
      unitPrice: 0,
      productCode: '',
      itemDate: moment(),
      tax: 0,
      amount: 0,
      currency: CURRENCIES[0], // Reset to default currency
    });
  };

  const handleDeleteItem = (id) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== id),
    }));
  };

  const handleEditItem = (item) => {
    setEditingItem(item.id);
    setNewItem(item);
  };

  const handleUpdateItem = () => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item => 
        item.id === editingItem ? { ...newItem, id: item.id } : item
      ),
    }));
    setEditingItem(null);
    setNewItem({
      itemDescription: '',
      itemQuantity: 0,
      unitPrice: 0,
      productCode: '',
      itemDate: moment(),
      tax: 0,
      amount: 0,
      currency: CURRENCIES[0], // Reset to default currency
    });
  };

  const handleClose = () => {
    onClose([]);
  };

  const handleCloseUploadFrame = useCallback((logoUrl, file) => {
    if (logoUrl) {
      setLogo(logoUrl);
      setLogoFile(file);
    }
    setShowUploadFrame(false);
  }, []);

  const handleRemoveLogo = useCallback(() => {
    setLogo(null);
  }, []);

  const [errors, setErrors] = useState({});

  return (
    <>
      {/* Backdrop to darken the background */}
      <div className="upload-backdrop" >
        <div className="loading-overlay">
          {showUploadFrame && <UploadTemplateFrame onClose={handleCloseUploadFrame} />}
            {/* Close Button */}
            <IconButton
                size="medium"
                sx={{
                position: "absolute",
                top: 18,
                right: 18,
                border: 1,
                borderColor: "#1c4ed8",
                color: "#1c4ed8",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "#f0f4ff" },
                }}
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{ p: 4 }}>
              <Box alignItems="center" sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    mt: 4,
                    mb: 2,
                    }}
                >
                <Typography variant="h4" gutterBottom>
                  {t("Create Invoice")}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {logo && (
                    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
                      <img 
                        src={logo} 
                        alt="Company Logo" 
                        style={{
                          height: '40px',
                          width: 'auto',
                          objectFit: 'contain',
                          marginRight: '10px'
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={handleRemoveLogo}
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: 0,
                          backgroundColor: '#fff',
                          border: '1px solid #1c4ed8',
                          padding: '2px',
                          '&:hover': {
                            backgroundColor: '#f0f4ff'
                          }
                        }}
                      >
                        <X size={12} color="#1c4ed8" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleTemplateClick}
                    sx={{ 
                      borderRadius: '10px', 
                      color: '#1c4ed8', 
                      backgroundColor: 'white', 
                      borderColor: '#1c4ed8' 
                    }}
                  >
                    {logo ? t("Change Company Logo") : t("Add Company Logo")}
                  </Button>
                </Box>
              </Box>
            {/* Basic Invoice Information */}
                      <Typography variant="h6" gutterBottom>
                      {t("Basic Information")}
                      </Typography>
                      <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                      <TextField
                        name="invoiceIdNumber"
                        label={t("Invoice ID Number")}
                        variant="outlined"
                        value={formData.invoiceIdNumber}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        error={!!errors.invoiceIdNumber}
                        helperText={errors.invoiceIdNumber}
                      />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      <TextField
                          name="vendorName"
                          label={t("Vendor Name")}
                          variant="outlined"
                          value={formData.vendorName}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          error={!!errors.vendorName}
                          helperText={errors.vendorName}
                      />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="customerName"
                          label={t("Customer Name")}
                          variant="outlined"
                          value={formData.customerName}
                          onChange={handleInputChange}
                          fullWidth
                          required
                          error={!!errors.customerName}
                          helperText={errors.customerName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="customerId"
                          label={t("Customer ID")}
                          variant="outlined"
                          value={formData.customerId}
                          onChange={handleInputChange}
                          fullWidth
                        />
                        </Grid>
                      
                      <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                        label={t("Invoice Date")}
                        value={formData.invoiceDate}
                        onChange={(value) => handleDateChange('invoiceDate', value)}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField 
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY"
                            }}
                            fullWidth 
                          />
                        )}
                        />
                      </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                          control={
                          <Checkbox
                            checked={formData.hasDueDate}
                            onChange={(e) => {
                            handleInputChange({
                              target: {
                              name: 'hasDueDate',
                              value: e.target.checked
                              }
                            });
                            // Clear due date when unchecking
                            if (!e.target.checked) {
                              handleDateChange('dueDate', null);
                            }
                            }}
                          />
                          }
                          label={t("Add Due Date")}
                        />
                        {formData.hasDueDate && (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            sx={{ mt: 2 }}
                            label={t("Due Date")}
                            value={formData.dueDate}
                            onChange={(value) => handleDateChange('dueDate', value)}
                            inputFormat="DD-MM-YYYY"
                            renderInput={(params) => (
                              <TextField 
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD-MM-YYYY"
                                }}
                                fullWidth 
                              />
                            )}
                          />
                          </LocalizationProvider>
                        )}
                        </Box>
                      </Grid>
                      
                    </Grid>
                      

                <Grid container spacing={3} sx={{my: 0}}>
                  
                  <Grid item xs={12} sm={6}>
                        <TextField
                        name="purchaseOrder"
                        label={t("Purchase Order")}
                        variant="outlined"
                        value={formData.purchaseOrder}
                        onChange={handleInputChange}
                        fullWidth
                        />
                      </Grid>
                  <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          name="category"
                          label={t("Invoice Category")}
                          value={formData.category}
                          onChange={handleInputChange}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category ? t(category) : t("Uncategorized")}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />

                {/* Financial Information */}
                <Typography variant="h6" gutterBottom>
                  {t("Bill Information")}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="subtotal"
                    label={t("Subtotal")}
                    variant="outlined"
                    value={formData.subtotal}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="invoiceTotal"
                    label={t("Invoice Total")}
                    variant="outlined"
                    value={formData.invoiceTotal}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!errors.invoiceTotal}
                    helperText={errors.invoiceTotal}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="totalTax"
                    label={t("Total Tax")}
                    variant="outlined"
                    value={formData.totalTax}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="previousUnpaidBalance"
                    label={t("Previous Unpaid Balance")}
                    variant="outlined"
                    value={formData.previousUnpaidBalance}
                    onChange={handleInputChange}
                    fullWidth
                  />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <TextField
                    name="amountDue"
                    label={t("Amount Due")}
                    variant="outlined"
                    value={formData.amountDue}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!errors.amountDue}
                    helperText={errors.amountDue}
                  />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />
                <ItemsSection
                  items={formData.items}
                  newItem={newItem}
                  onItemChange={handleItemChange}
                  onItemDateChange={handleItemDateChange}
                  onAddItem={handleAddItem}
                  onDeleteItem={handleDeleteItem}
                  onEditItem={handleEditItem}
                  onUpdateItem={handleUpdateItem}
                  editingItem={editingItem}
                  t={t}
                  errors={errors}
                />
                <Divider sx={{ my: 3 }} />

                {/* Custom Fields */}
                <Typography variant="h6" gutterBottom>
                  {t("Custom Fields")}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.addCustomFields}
                        onChange={(e) => handleInputChange({
                          target: {
                            name: 'addCustomFields',
                            value: e.target.checked
                          }
                        })}
                      />
                    }
                    label={t("Add Custom Field")}
                  />
                </Box>

                {formData.addCustomFields && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="customFieldKey"
                          label={t("Field Name")}
                          variant="outlined"
                          value={formData.customFieldKey || ''}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="customFieldValue"
                          label={t("Field Value")}
                          variant="outlined"
                          value={formData.customFieldValue || ''}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCustomField}
                        disabled={!formData.customFieldKey || !formData.customFieldValue}
                        sx={{
                          height: '100%',
                          pl: 2,
                          pr: 2,
                          color: "white",
                          backgroundColor: "#1c4ed8",
                          borderRadius: "50px",
                          "&:hover": { backgroundColor: "#163bb7" },
                        }}
                      >
                        {t("Add Field")}
                      </Button>
                      </Grid>
                    </Grid>

                    {/* Display Custom Fields List */}
                    {formData.customFields.length > 0 && (
                      <Box sx={{ mt: 3, mb: 2 }}>
                        <List sx={{ 
                          backgroundColor: '#f8fafc',
                          borderRadius: '8px',
                          border: '1px solid #e2e8f0',
                          p: 0
                        }}>
                          {formData.customFields.map((field, index) => (
                            <ListItem
                              key={index}
                              sx={{
                                borderBottom: index < formData.customFields.length - 1 ? '1px solid #e2e8f0' : 'none',
                                py: 1.5,
                                '&:hover': {
                                  backgroundColor: '#f1f5f9'
                                }
                              }}
                              secondaryAction={
                                <IconButton 
                                  edge="end" 
                                  onClick={() => handleRemoveCustomField(index)}
                                  sx={{
                                    color: '#ef4444',
                                    '&:hover': {
                                      backgroundColor: 'rgba(239, 68, 68, 0.1)'
                                    }
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              }
                            >
                              <ListItemText 
                                primary={
                                  <Typography sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    fontWeight: 500
                                  }}>
                                    <span style={{ color: '#64748b' }}>{field.key}:</span>
                                    <span>{field.value}</span>
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                  </>
                )}

                  
            <Divider sx={{ my: 3 }} />

            {/* Address Sections */}
            <AddressFields
              prefix="vendorAddress"
              title={t("Vendor Address")}
              address={formData.vendorAddress}
              onChange={handleInputChange}
              t={t}
            />

            <AddressFields
              prefix="customerAddress"
              title={t("Customer Address")}
              address={formData.customerAddress}
              onChange={handleInputChange}
              t={t}
            />

            {/* Billing Address Section */}
            <Box sx={{ mb: 2 }}>
                <FormControlLabel
                control={
                    <Checkbox
                    checked={sameAsCustomer}
                    onChange={handleSameAsCustomerChange}
                    name="sameAsCustomer"
                    />
                }
                label={t("Billing address different from the customer address")}
                />
            </Box>

            {/* Conditional Billing Address */}
            {sameAsCustomer && (
              <>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                    {t("Billing Address")}
                    </Typography>
                    <AddressFields
                        prefix="billingAddress"
                        title=""
                        address={formData.billingAddress}
                        onChange={handleInputChange}
                        t={t}
                    />
                </Box>
              </>
            )}

            {/* Optional Addresses */}
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6" gutterBottom>
                {t("Additional Addresss")}
                </Typography>
                <OptionalAddressFields
                  prefix="shippingAddress"
                  title={t("Shipping Address")}
                  address={formData.shippingAddress}
                  onChange={handleInputChange}
                  t={t}
                />
                <OptionalAddressFields
                  prefix="serviceAddress"
                  title={t("Service Address")}
                  address={formData.serviceAddress}
                  onChange={handleInputChange}
                  t={t}
                />
                <OptionalAddressFields
                  prefix="remittanceAddress"
                  title={t("Remittance Address")}
                  address={formData.remittanceAddress}
                  onChange={handleInputChange}
                  t={t}
                />
            </Box>

            <Grid item xs={12}>
                <Box 
                    sx={{ 
                    display: "flex", 
                    justifyContent: "center", 
                    gap: 2, // spacing between buttons
                    mt: 5 
                    }}
                >
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={isLoading ? <CircularProgress size={20} style={{ color: "white" }} /> : null}
                      onClick={handleSubmit}
                      disabled={isLoading}
                      sx={{
                          pl: 4,
                          pr: 4,
                          color: "white",
                          backgroundColor: "#1c4ed8",
                          borderRadius: "50px",
                          "&:hover": { backgroundColor: "#163bb7" },
                      }}
                      >
                    {t("Create Invoice")}
                    </Button>
                    <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose} // Assuming 'Cancel' should call handleClose
                    sx={{
                        pl: 4,
                        pr: 4,
                        borderRadius: "50px",
                    }}
                    >
                    {t("Cancel")}
                    </Button>
                </Box>
                </Grid>
            </Box>
        </div>
        </div>
        <style jsx>{`
        .upload-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 999;
            }
        .loading-overlay {
            position: absolute;
            overflow: scroll;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1100px;
            height: 90%;
            padding: 20px;
            background-color: rgba(255, 255, 255, 0.8); /* Optional: add a slight background color */
            align-items: center;
            justify-content: center;
            z-index: 1100; /* Ensure it sits above the upload frame */
            border-radius: 25px; /* Match the border radius of the upload frame */
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            }
      `}</style>
    </>
  );
};