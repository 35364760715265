import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Navbar } from '../../components/Navbar/Navbar';
import { Pricing } from '../../pages/Pricing/Pricing';
import { SignupForm } from '../../components/SignupForm/SignupForm';
import { HeroSection } from '../../components/HeroSection/HeroSection';
import { Box, Container, Fab } from '@mui/material';
import { FeatureCard } from '../../components/FeatureCard/FeatureCard';
import { StatSection } from '../../components/StatSection/StatSection';
import { IntegrationFrame } from '../../components/IntegrationFrame/IntegrationFrame';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const fadeIn = {
  initial: { opacity: 0, y: 40 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8 },
};

// Add scroll to top button animation
const scrollButtonVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const Home = () => {
  const [showScrollButton, setShowScrollButton] = React.useState(false);
  
  // Animation controls and in-view detection for sections
  const [statsRef, statsInView] = useInView({ triggerOnce: true, threshold: 0.8 });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [pricingRef, pricingInView] = useInView({ triggerOnce: true, threshold: 0.4 });
  const [integrationsRef, integrationsInView] = useInView({ triggerOnce: true, threshold: 0.5 });

  const statsAnimation = useAnimation();
  const featuresAnimation = useAnimation();
  const pricingAnimation = useAnimation();
  const integrationsAnimation = useAnimation();

  // Add scroll handler
  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 800); // Show button after 800px scroll
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    if (statsInView) statsAnimation.start('animate');
    if (featuresInView) featuresAnimation.start('animate');
    if (pricingInView) pricingAnimation.start('animate');
    if (integrationsInView) integrationsAnimation.start('animate');
  }, [statsInView, featuresInView, pricingInView, integrationsInView, 
      statsAnimation, featuresAnimation, pricingAnimation, integrationsAnimation]);

  return (
    <Box
      className="min-h-screen"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 0,
        background: '#FAFAF8',
        position: 'relative',
      }}
    >
      <Box sx={{ width: '100%', maxWidth: '100%', px: 0 }}>
        <Navbar sideBarOpen={true} />

        {/* Hero Section */}
        <HeroSection />

      <Container maxWidth="lg">
        {/* Stats Section */}
        <motion.div ref={statsRef} initial="initial" animate={statsAnimation} variants={fadeIn}>
          <StatSection />
        </motion.div>

        {/* Features Section */}
        <motion.div ref={featuresRef} initial="initial" animate={featuresAnimation} variants={fadeIn}>
          <FeatureCard />
        </motion.div>

        {/* Pricing Section */}
        <motion.div ref={pricingRef} initial="initial" animate={pricingAnimation} variants={fadeIn}>
          <Box sx={{ width: '100%' }}>
            <Pricing />
          </Box>
        </motion.div>

        {/* Integration Frame */}
        <motion.div ref={integrationsRef} initial="initial" animate={integrationsAnimation} variants={fadeIn}>
          <IntegrationFrame />
        </motion.div>
        </Container>
          {/* Signup Form */}
          <SignupForm />
      </Box>

      {/* Add Scroll to Top Button */}
      <motion.div
        initial="hidden"
        animate={showScrollButton ? "visible" : "hidden"}
        variants={scrollButtonVariants}
        transition={{ duration: 0.3 }}
        style={{
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
          zIndex: 1000,
        }}
      >
        <Fab
          onClick={scrollToTop}
          size="medium"
          sx={{
            backgroundColor: '#1c4ed8', // Deep navy blue
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: 'rgba(28, 78, 216, 0.8)', // Lighter shade for hover
            },
            boxShadow: '0 4px 14px 0 rgba(45, 50, 80, 0.25)',
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </motion.div>
    </Box>
  );
};
